import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import FontProvider from './providers/FontProvider';
import ReduxProvider from './providers/ReduxProvider';
import JoyProvider from './providers/JoyProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BrowserRouter } from 'react-router-dom'
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ReduxProvider>
    <BrowserRouter>
        <FontProvider>
          <JoyProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </JoyProvider>
        </FontProvider>
    </BrowserRouter>
  </ReduxProvider>
)
import { IconButton } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditRunModal from "../popups/EditRunModal/EditRunModal";
import ModalBase from "../popups/ModalBase";
import { useState } from "react";

export default function EditRunButton({run, crews, setCrews, setAlertMessage}) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <IconButton
                size="small"
                sx={{
                    color: '#1976d2',
                    '&:hover': {
                        filter: 'brightness(0.9)',
                    },
                }}
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <EditOutlinedIcon sx={{fontSize: '14px'}} />
            </IconButton>
            {open && (
                <ModalBase 
                    minWidth={'720px !important'}
                    open={open}
                    handleClose={() => {
                        setOpen(false);
                    }}
                >
                    <EditRunModal run={run} crews={crews} setCrews={setCrews} handleClose={() => {setOpen(false)}} setAlertMessage={setAlertMessage}/>
                </ModalBase>
            )}
        </>
    )
}
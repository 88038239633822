import { Routes, Route, Outlet } from "react-router-dom"
import Home from '../features/home/Home';
import Survey from "../features/survey/components/Survey";
import Assessment from "../features/assessment/components/Assessment";
import ConstructionServices from "../features/construction-services/components/ConstructionServices";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/scheduler" element={<Outlet></Outlet>}>
        <Route path="survey" element={<Survey />} />
        <Route path="construction-services" element={<ConstructionServices />} />
        <Route path="assessment" element={<Assessment />} />
      </Route>
    </Routes>
  );
}

export default App;

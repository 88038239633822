import { IconButton } from "@mui/material"
import { Tooltip } from "@mui/material";

export default function IconButtonBase({children, handleClick, tooltip, styles}) {
    return (
        <Tooltip title={tooltip} placement="bottom">
            <IconButton
                size="small"
                color="inherit"
                sx={{ mr: "4px", ...styles }}
                onClick={handleClick}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
}
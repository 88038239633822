export function handleDrop(result, selectedItems, setSelectedItems, crews, setCrews, unassigned, setUnassigned, transfers, setTransfers, setUnsavedChanges) {
    console.log(result)
    // If the drop is outside of a droppable, do nothing
    if (!result.destination) return;

    if (selectedItems.length > 1) {

        // If the destination is unassigned or transfer, do nothing
        if (result.destination.droppableId === 'unassigned' || result.destination.droppableId === 'transfer') return;

        // If the source is unassigned or transfer, do nothing
        if (result.source.droppableId === 'unassigned' || result.source.droppableId === 'transfer') return;

        selectedItems.forEach((item) => {
            // Find the source crew and destination crew
            const sourceCrew = crews.find(crew => crew['6'] === item['crew']);
            const destinationCrew = crews.find(crew => crew['6'] === String(result.destination.droppableId).split('-')[1]);
            // Find the source run and destination run
            const sourceRun = sourceCrew['runs'].find(run => run['3'] === item['run']);
            const destinationRun = destinationCrew['runs'].find(run => run['3'] === Number(String(result.destination.droppableId).split('-')[0]));
            // Using the draggabbleID, find the assignment from the source run
            const assignment = sourceRun['assignments'].find(assignment => assignment['3'] === item['assignment']);
            // Remove the assignment from the source run and add it to the destination run
            const sourceAssignmentIndex = sourceRun['assignments'].findIndex(assignment => assignment['3'] === item['assignment']);
            sourceRun['assignments'].splice(sourceAssignmentIndex, 1);

            // Update the destination crew
            const destinationIndex = result.destination.index;
            destinationRun['assignments'].splice(destinationIndex, 0, assignment);
            
            // Update the crews state
            const newCrews = [...crews];
            const sourceCrewIndex = newCrews.findIndex(crew => crew['6'] === sourceCrew['6']);
            const destinationCrewIndex = newCrews.findIndex(crew => crew['6'] === destinationCrew['6']);
            newCrews[sourceCrewIndex] = sourceCrew;
            newCrews[destinationCrewIndex] = destinationCrew;
            setCrews(newCrews);
        })

        setSelectedItems([]);
        setUnsavedChanges(true);

    } else {
        // If the source and destination are the same, order the assignments based on the result.destination.index
        if (result.source.droppableId === result.destination.droppableId) {
            // If the source is NOT the unassigned droppable, update the assignment records
            if (result.source.droppableId !== 'unassigned' && result.source.droppableId !== 'transfer') {
                const sourceCrew = crews.find(crew => crew['6'] === String(result.source.droppableId).split('-')[1]);
                const sourceRun = sourceCrew['runs'].find(run => run['3'] === Number(String(result.source.droppableId).split('-')[0]));
                const assignment = sourceRun['assignments'].find(assignment => assignment['3'] === Number(result.draggableId));
                sourceRun['assignments'].splice(result.source.index, 1);
                sourceRun['assignments'].splice(result.destination.index, 0, assignment);
                const newCrews = [...crews];
                const sourceCrewIndex = newCrews.findIndex(crew => crew['6'] === sourceCrew['6']);
                newCrews[sourceCrewIndex] = sourceCrew;
                setCrews(newCrews);
                setSelectedItems([]);
                setUnsavedChanges(true);
                return;
            }
            return;
        }

        // If the source is the transfer droppable...
        // 1. Remove the assignment from the transfer records
        // 2. Add the assignment to the destination run
        // 3. Update the crews state
        if (result.source.droppableId === 'transfer') {
            const assignment = transfers.find(assignment => assignment['3'] === Number(result.draggableId));
            // Update the transfer records
            const newTransfers = [...transfers];
            const assignmentIndex = newTransfers.findIndex(assignment => assignment['3'] === Number(result.draggableId));
            newTransfers.splice(assignmentIndex, 1);
            setTransfers(newTransfers);
            // Update the destination run
            const destinationCrew = crews.find(crew => crew['6'] === String(result.destination.droppableId).split('-')[1]);
            const destinationRun = destinationCrew['runs'].find(run => run['3'] === Number(String(result.destination.droppableId).split('-')[0]));

            // Update the destination run
            const destinationIndex = result.destination.index;
            destinationRun['assignments'].splice(destinationIndex, 0, assignment);
            
            // Update the crews state
            const newCrews = [...crews];
            const destinationCrewIndex = newCrews.findIndex(crew => crew['6'] === destinationCrew['6']);
            newCrews[destinationCrewIndex] = destinationCrew;
            setCrews(newCrews);
            setSelectedItems([]);
            setUnsavedChanges(true);
            return;
        }

        // If the destination is the transfer droppable...
        // 1. Add the assignment to the transfer records
        // 2. Remove the assignment from the source run
        // 3. Update the crews state
        if (result.destination.droppableId === 'transfer') {
            const sourceCrew = crews.find(crew => crew['6'] === String(result.source.droppableId).split('-')[1]);
            const sourceRun = sourceCrew['runs'].find(run => run['3'] === Number(String(result.source.droppableId).split('-')[0]));
            const assignment = sourceRun['assignments'].find(assignment => assignment['3'] === Number(result.draggableId));
            // Update the transfer records
            const newTransfers = [...transfers];
            newTransfers.push(assignment);
            setTransfers(newTransfers);
            // Update the source run
            const sourceAssignmentIndex = sourceRun['assignments'].findIndex(assignment => assignment['3'] === Number(result.draggableId));
            sourceRun['assignments'].splice(sourceAssignmentIndex, 1);
            // Update the crews state
            const newCrews = [...crews];
            const sourceCrewIndex = newCrews.findIndex(crew => crew['6'] === sourceCrew['6']);
            newCrews[sourceCrewIndex] = sourceCrew;
            setCrews(newCrews);
            setSelectedItems([]);
            setUnsavedChanges(true);
            return;
        }

        // If the source is the unassigned droppable...
        // 1. Remove the assignment from the unassigned records
        // 2. Add the assignment to the destination run
        // 3. Update the crews state
        if (result.source.droppableId === 'unassigned') {
            const assignment = unassigned.find(assignment => assignment['3'] === Number(result.draggableId));
            // Update the unassigned records
            const newUnassigned = [...unassigned];
            const assignmentIndex = newUnassigned.findIndex(assignment => assignment['3'] === Number(result.draggableId));
            newUnassigned.splice(assignmentIndex, 1);
            setUnassigned(newUnassigned);
            // Update the destination run
            const destinationCrew = crews.find(crew => crew['6'] === String(result.destination.droppableId).split('-')[1]);
            const destinationRun = destinationCrew['runs'].find(run => run['3'] === Number(String(result.destination.droppableId).split('-')[0]));

            // Update the destination crew
            const destinationIndex = result.destination.index;
            destinationRun['assignments'].splice(destinationIndex, 0, assignment);
            
            // Update the crews state
            const newCrews = [...crews];
            const destinationCrewIndex = newCrews.findIndex(crew => crew['6'] === destinationCrew['6']);
            newCrews[destinationCrewIndex] = destinationCrew;
            setCrews(newCrews);
            setSelectedItems([]);
            setUnsavedChanges(true);
            return;
        }

        // If the destination is the unassigned droppable...
        // 1. Add the assignment to the unassigned records
        // 2. Remove the assignment from the source run
        // 3. Update the crews state
        if (result.destination.droppableId === 'unassigned') {
            const sourceCrew = crews.find(crew => crew['6'] === String(result.source.droppableId).split('-')[1]);
            const sourceRun = sourceCrew['runs'].find(run => run['3'] === Number(String(result.source.droppableId).split('-')[0]));
            const assignment = sourceRun['assignments'].find(assignment => assignment['3'] === Number(result.draggableId));
            // Update the unassigned records
            const newUnassigned = [...unassigned];
            newUnassigned.push(assignment);
            setUnassigned(newUnassigned);
            // Update the source run
            const sourceAssignmentIndex = sourceRun['assignments'].findIndex(assignment => assignment['3'] === Number(result.draggableId));
            sourceRun['assignments'].splice(sourceAssignmentIndex, 1);
            // Update the crews state
            const newCrews = [...crews];
            const sourceCrewIndex = newCrews.findIndex(crew => crew['6'] === sourceCrew['6']);
            newCrews[sourceCrewIndex] = sourceCrew;
            setCrews(newCrews);
            setSelectedItems([]);
            setUnsavedChanges(true);
            return;
        }

        // Find the source crew and destination crew
        const destinationCrew = crews.find(crew => crew['6'] === String(result.destination.droppableId).split('-')[1]);
        const sourceCrew = crews.find(crew => crew['6'] === String(result.source.droppableId).split('-')[1]);
        // Find the source run and destination run
        const sourceRun = sourceCrew['runs'].find(run => run['3'] === Number(String(result.source.droppableId).split('-')[0]));
        const destinationRun = destinationCrew['runs'].find(run => run['3'] === Number(String(result.destination.droppableId).split('-')[0]));
        // Using the draggabbleID, find the assignment from the source run
        const assignment = sourceRun['assignments'].find(assignment => assignment['3'] === Number(result.draggableId));
        // Remove the assignment from the source run and add it to the destination run
        const sourceAssignmentIndex = sourceRun['assignments'].findIndex(assignment => assignment['3'] === Number(result.draggableId));
        sourceRun['assignments'].splice(sourceAssignmentIndex, 1);

        // Update the destination crew
        const destinationIndex = result.destination.index;
        destinationRun['assignments'].splice(destinationIndex, 0, assignment);
        
        // Update the crews state
        const newCrews = [...crews];
        const sourceCrewIndex = newCrews.findIndex(crew => crew['6'] === sourceCrew['6']);
        const destinationCrewIndex = newCrews.findIndex(crew => crew['6'] === destinationCrew['6']);
        newCrews[sourceCrewIndex] = sourceCrew;
        newCrews[destinationCrewIndex] = destinationCrew;
        setCrews(newCrews);
        setSelectedItems([]);
        setUnsavedChanges(true);
        return;
    }
};
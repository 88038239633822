import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import getCookie from './CSRF';

const BASE_URL = process.env.REACT_APP_API;

const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken')
};

export const RunApi = createApi({
  reducerPath: 'RunApi',
  tagTypes: ['Runs'],
  baseQuery: fetchBaseQuery({baseUrl: `${BASE_URL}/runs`}),
  endpoints: (builder) => ({
    editRun: builder.mutation({
      query: ({id, data}) => ({
        url: `edit/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
        headers: headers,
      }),
      onQueryStarted: () => {
        console.log('updateRuns query started');
      },
    }),
  }),
});

export const { useEditRunMutation } = RunApi;

import { Tooltip, IconButton } from "@mui/material"
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
export default function ShowTransfersButton({setShowTransfers, showTransfers}) {

    return (
        <Tooltip title="Transfers" placement="left">
            <IconButton 
                onClick={() => 
                    setShowTransfers(!showTransfers)
                }
                sx={{
                    position: "fixed", 
                    backgroundColor: '#2772E1', 
                    bottom: "82px", 
                    right: "20px", 
                    height: "48px", 
                    width: "48px", 
                    lineHeight: "24px", 
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 8px 4px",
                    "&:hover": {
                        backgroundColor: '#2466CB',
                    }
                }}>
                <SwapHorizOutlinedIcon sx={{height: "24px", width: "24px", color: "#fff"}} />
            </IconButton>
        </Tooltip>
    )
}
import {CrewApi} from '../../services/CrewApi';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  display: {
    'filters_shown': true,
    'search_shown': true,
    'unassigned_shown': false,
  }
};

export const SchedulerSlice = createSlice({
  name: 'scheduler',
  initialState: initialState,
  reducers: {
    setSchedulerDisplay: (state, action) => {
      state.display = action.payload;
    },
    resetSchedulerState: () => initialState,
  },
  // The `extraReducers` field lets the slice handle actions defined in the authApi slice
  extraReducers: (builder) => {
    builder
      .addMatcher(CrewApi.endpoints.getCrews.matchFulfilled, (state, {payload}) => {
        state.crews = payload;
      })
  },
});

// Action creators are generated for each case reducer function
export const {setSchedulerDisplay, resetSchedulerState} = SchedulerSlice.actions;

export const selectDisplay = (state) => state.scheduler.display;

export default SchedulerSlice.reducer;

import { TextField, InputAdornment, IconButton } from "@mui/material"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const convertDateToReadable = (date) => {
    const splitDate = date.split('-');
    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];
    return `${month}/${day}/${year}`;
}

const convertDate = (date) => {
    const splitDate = date.split('/');
    const year = splitDate[2];
    const month = splitDate[0];
    const day = splitDate[1];
    return `${year}-${month}-${day}`;
}

export default function DateFilter({column, filters, setFilters}) {
    const filter = filters.find(f => f.column === column.id)?.value || "";
    return (
        <TextField
        fullWidth
        type="date"
        placeholder={`Filter by ${column.label}`}
        sx={{
            fontSize: "12px",
            padding: "0px",
            "& .MuiInputBase-root": { 
                padding: '0',
                pb: '4px',
            },
            '& fieldset': {
                border: 'none',
                borderBottom: '1px solid rgb(51, 51, 51)',
                borderRadius: '0px',
                padding: '0px',
            },
            '& input:focus': {
                backgroundColor: '#fff !important',
                border: 'none',
            },
            '& input': {
                border: 'none',
                borderRadius: '0px',
                padding: '0px'
            },
            '& input:hover': {
                backgroundColor: 'inherit',
                border: 'none',
            },
        }}
        inputProps={{ 
            style: {
                fontSize: '12px',
                padding: '0px',
                lineHeight: '1.6',
            }
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="clear input"
                    onClick={() => {
                        const newFilters = [...filters];
                        const index = newFilters.findIndex(f => f.column === column.id);
                        if (index !== -1) {
                            newFilters[index].value = "";
                        }
                        setFilters(newFilters);
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    <CloseOutlinedIcon sx={{color: "#D9DBDE", fontSize: "12px"}} />
                  </IconButton>
                </InputAdornment>
              ),
        }}
        value={filter !== "" ? convertDate(filter) : ""}
        onChange={(e) => {
            const newFilters = [...filters];
            const index = newFilters.findIndex(f => f.column === column.id);
            if (index === -1) {
                newFilters.push({column: column.id, filter: convertDateToReadable(e.target.value)});
            } else {
                newFilters[index].value = convertDateToReadable(e.target.value);
            }
            setFilters(newFilters);
        }}
    />       
    )
}
import { IconButton, Typography } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export default function ModalHeader({handleClose, message}) {

    return (
        <div className='flex flex-row items-center relative px-6 py-2 border-b border-gray-200'>

            <Typography sx={{fontSize: '1.25rem', fontWeight: '600'}}>
                {message}
            </Typography>

            <IconButton
                onClick={() => {
                    handleClose();
                }}
                sx={{
                    height: '32px',
                    width: '32px',
                    color: 'rgb(51, 51, 51)',
                    position: 'absolute',
                    right: '1rem',
                    top: '0px',
                }}
            >
            <CloseOutlinedIcon sx={{fontSize: '18px'}}/>
            </IconButton>

        </div>
    )
}
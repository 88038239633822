import { Droppable, Draggable } from "react-beautiful-dnd"
import { Typography, Checkbox } from "@mui/material"

export default function Transfers({transferRecords, filters}) {

    const getFilteredRecords = () => {
        // If each filter object's value property within filter is empty, return the assignment
        if (filters.every((filter) => filter.value === "")) return transferRecords || [];
        // Else, filter all properties on the assignment whose matching filter value is not empty
        return transferRecords.filter((assignment) => {
            return filters.every((filter) => {
                if (filter.value === "") return true; // Skip empty filters
                const assignmentValue = assignment[filter.field_id];
                if (typeof assignmentValue === 'string') {
                    return assignmentValue.toLowerCase().includes(filter.value.toLowerCase());
                } 
                if (typeof assignmentValue === 'number') {
                    return assignmentValue.toString().includes(filter.value);
                }
                return true; // or some other default behavior for unsupported types
            });
        });
    }

    const filteredRecords = getFilteredRecords();

    return (
        <div className="w-[2400px] bg-white flex flex-col flex-1">
            <Droppable droppableId={'transfer'}>
            {(provided, snapshot) => {
                return (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="flex flex-col w-full h-full bg-white"
                    >
                        { 
                            filteredRecords.length > 0 ?
                            filteredRecords.map((record) => (
                                    <TransferRecord key={record?.['3']} record={record} />
                                ))
                            :
                            <div className="w-full h-full flex items-center justify-center">
                                <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', width: '100%', textAlign: 'center'}}>No records to display</Typography>
                            </div>
                        }
                        {provided.placeholder}
                    </div>
                )
            }}
            </Droppable>
        </div>
    )
}

function TransferRecord({record}) {
    return (
        <Draggable key={record?.['3']} draggableId={String(record?.['3'])} index={record?.['3']}>
            {(provided, snapshot) => {
                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`flex flex-row h-8 w-full items-center ${snapshot.isDragging ? 'bg-[#def4ff]' : 'bg-white'} relative border-b border-gray-200`}
                    >
                        <div className="h-full w-[150px] px-2 border-t border-r border-gray-200 sticky left-0 z-9999 bg-inherit flex items-center" title={record?.['46']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['46']}</Typography>
                        </div>

                        <div className="h-full w-[150px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['102']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['102']}</Typography>
                        </div>

                        <div className="h-full w-[150px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['86']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['86']}</Typography>
                        </div>

                        <div className="h-full w-[200px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['87']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['87']}</Typography>
                        </div>

                        <div className="h-full w-[150px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['66']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['66']}</Typography>
                        </div>

                        <div className="h-full w-[200px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['64']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['64']}</Typography>
                        </div>

                        <div className="h-full w-[250px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['61']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['61']}</Typography>
                        </div>

                        <div className="h-full w-[200px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['52']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['52']}</Typography>
                        </div>

                        <div className="h-full w-[100px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                            <Checkbox
                                size="small"
                                sx={{
                                    color: '#4B5563',
                                }}
                                checked={record?.['139']}
                            />
                        </div>

                        <div className="h-full w-[250px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                            <Checkbox
                                size="small"
                                sx={{
                                    color: '#4B5563',
                                }}
                                checked={record?.['184']}
                            />
                        </div>

                        <div className="h-full w-[300px] px-2 border-t border-r border-gray-200 flex items-center justify-center" title={record?.['187']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['187']}</Typography>
                        </div>

                        <div className="h-full w-[300px] px-2 border-t border-gray-200 flex items-center justify-center" title={record?.['50']}>
                            <Typography sx={{fontSize: '12px', fontWeight: '400', color: '#4B5563', width: '300px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{record?.['50']}</Typography>
                        </div>
                        
                    </div>
                )
            }}
        </Draggable>
    )
}
import CheckboxFilter from "./CheckboxFilter";
import TextFilter from "./TextFilter";
import DateFilter from "./DateFilter";

export default function ColumnFilter({column, filters, setFilters}) {
    switch (column.type) {
        case "boolean":
            return <CheckboxFilter column={column} filters={filters} setFilters={setFilters} />
        case "text":
            return <TextFilter column={column} filters={filters} setFilters={setFilters} />
        case "select":
                return <TextFilter column={column} filters={filters} setFilters={setFilters} />
        case "date":
            return <DateFilter column={column} filters={filters} setFilters={setFilters} />
        default:
            return null;
    }
}
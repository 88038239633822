import {store, persistor} from '../redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

// A wrapper component that provides the Redux store to the React component tree
// Also, includes the PersistGate to handle the rehydration of the Redux store from persisted state
export default function ReduxProvider({children}) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}

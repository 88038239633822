import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import {getPersistConfig} from 'redux-deep-persist';
import storage from './storage';
import ColumnReducer from './reducers/ColumnSlice';
import SchedulerReducer from './reducers/SchedulerSlice';
import CrewReducer from './reducers/CrewSlice';
import EmployeeReducer from './reducers/EmployeeSlice';
import AssignmentReducer from './reducers/AssignmentSlice';
import {CrewApi} from '../services/CrewApi';
import {EmployeeApi} from '../services/EmployeeApi';
import {AssignmentApi} from '../services/AssignmentApi';
import { RunApi } from '../services/RunApi';
import {SchedulerApi} from '../services/SchedulerApi';
import {setupListeners} from '@reduxjs/toolkit/query';

// Combining all the reducers to create the root reducer
const rootReducer = combineReducers({
  column: ColumnReducer,
  crew: CrewReducer,
  employee: EmployeeReducer,
  scheduler: SchedulerReducer,
  assignment: AssignmentReducer,
  [AssignmentApi.reducerPath]: AssignmentApi.reducer,
  [CrewApi.reducerPath]: CrewApi.reducer,
  [EmployeeApi.reducerPath]: EmployeeApi.reducer,
  [SchedulerApi.reducerPath]: SchedulerApi.reducer,
  [RunApi.reducerPath]: RunApi.reducer,
});

const config = getPersistConfig({
  key: 'root',
  storage: storage,
  blacklist: ['CrewApi', 'EmployeeApi', 'AssignmentApi', 'column', 'SchedulerApi', 'RunApi'],
  rootReducer,
});

// Applying the persistor configuration to the root reducer
const persistedReducer = persistReducer(config, rootReducer);

// Creating and configuring the Redux store with middlewares and persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(CrewApi.middleware, EmployeeApi.middleware, AssignmentApi.middleware, SchedulerApi.middleware, RunApi.middleware),
});

// Setting up listeners for the Redux Toolkit query APIs
setupListeners(store.dispatch);

// Creating a persistor object to be used for creating a persisted version of the Redux store
const persistor = persistStore(store);

// Exporting the configured store and persistor for use in the application
export {store, persistor};

import SaveButton from './SaveButton';
import CancelButton from './CancelButton';

export default function Buttons({submit, handleClose}) {
    return (
        <div className="flex flex-row px-6 py-2 mt-4">
            <CancelButton handleClose={handleClose}/>
            <SaveButton submit={submit}/>
        </div>
    )
}
import { TextField } from "@mui/material";

export default function ModalDateField({value, handleChange}) {
    return (
        <TextField
        margin="none"
        fullWidth
        type="date"
        autoComplete='off'
        onChange={(e) => {
            handleChange(e);
        }}
        value={value}
        sx={{
          'fontSize': '13px',
          'margin': '8px 0 8px',
          '& fieldset': {
            border: '1px solid #D3D3D3',
          },
          '& input:focus': {
            backgroundColor: '#fff',
            border: 'none',
          },
          '& input': {
            border: 'none',
          },
          '& input:hover': {
            backgroundColor: '#fff',
          },
        }}
        inputProps={{
          style: {
            fontSize: '13px',
            padding: '8px',
          },
        }}
      />
    )
}
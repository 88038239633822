// import { Checkbox } from "@mui/material"

export default function CheckboxFilter({column, filters, setFilters}) {
    // const filter = filters.find(f => f.column === column.id)?.filter || false;
    return (
        // <Checkbox
        //     size="small"
        //     sx={{
        //         color: "rgb(51, 51, 51)",
        //         fontSize: "18px",
        //         textAlign: "center",
        //     }}
        //     checked={filter}
        //     onChange={(e) => {
        //         const newFilters = [...filters];
        //         const index = newFilters.findIndex(f => f.column === column.id);
        //         if (index !== -1) {
        //             newFilters[index].filter = e.target.checked;
        //         } else {
        //             newFilters.push({column: column.id, filter: e.target.checked});
        //         }
        //         setFilters(newFilters);
        //     }}
        //     // inputProps={{ 'aria-label': 'controlled' }}
        // />
        null   
    )
}
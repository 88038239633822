import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
  } from '@mui/material/styles';
  import {CssVarsProvider as JoyCssVarsProvider} from '@mui/joy/styles';
  
  const materialTheme = materialExtendTheme({
    typography: {
      fontFamily: 'Poppins, sans-serif',
    },
  });
  
  export default function JoyProvider({children}) {
    return (
      <MaterialCssVarsProvider theme={{[MATERIAL_THEME_ID]: materialTheme}}>
        <JoyCssVarsProvider>
          {children}
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    );
  }
import { Box, Typography, Checkbox, TextField, FormControl, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import { Draggable } from "react-beautiful-dnd"
import EditAssignmentButton from "./buttons/EditAssignmentButton";
import ViewAssignmentButton from "./buttons/ViewAssignmentButton";
import TransferAssignmentButton from "./buttons/TransferAssignmentButton";

const getColorPair = (snapshot, assignment, selectedItems) => {
    if (selectedItems.filter(item => item.assignment === assignment['3']).length > 0) {
        return { backgroundColor: "#def4ff", textColor: "rgb(51, 51, 51)" };
    }

    if (snapshot.isDragging) {
        return { backgroundColor: "#def4ff", textColor: "rgb(51, 51, 51)" };
    }

    if (assignment?.['173'] === true) {
        return { backgroundColor: "#ADD8E6", textColor: "rgb(51, 51, 51)" };
    }

    if (assignment?.['134'] === true) {
        return { backgroundColor: "#C4D9B0", textColor: "rgb(51, 51, 51)" };
    }

    if (assignment?.['175'] === 'Cancelled') {
        return { backgroundColor: "#F50902", textColor: "#fff" };
    }

    if (assignment?.['175'] === 'Hold') {
        return { backgroundColor: "#000", textColor: "#fff" };
    }

    return { backgroundColor: "#fff", textColor: "rgb(51, 51, 51)" };
}

const convertDate = (date) => {
    const splitDate = date.split('/');
    const year = splitDate[2];
    const month = splitDate[0];
    const day = splitDate[1];
    return `${year}-${month}-${day}`;
}

const convertDateToReadable = (date) => {
    const splitDate = date.split('-');
    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];
    return `${month}/${day}/${year}`;
}

export default function Assignment({assignment, columns, index, crews, setCrews, run, crew, setAlertMessage, employees, selectedItems, setSelectedItems}) {
    const shownColumns = columns.filter(column => column.shown);
    const [editMode, setEditMode] = useState({});
    const editableFieldIds = [47, 48, 57, 60, 189, 208, 219];
    return (
        <Draggable key={assignment?.['3']} draggableId={String(assignment?.['3'])} index={index}>
            {(provided, snapshot) => {
                const { backgroundColor, textColor } = getColorPair(snapshot, assignment, selectedItems);
                return (
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={(e) => {
                            if (e.ctrlKey || e.metaKey) {
                                // If the item is already selected, remove it from the selected items
                                if (selectedItems.filter(item => item.assignment === assignment['3']).length > 0) {
                                    const updatedSelectedItems = selectedItems.filter(item => item.assignment !== assignment['3']);
                                    setSelectedItems(updatedSelectedItems);
                                } else {
                                    setSelectedItems([...selectedItems, {
                                        "assignment": assignment['3'],
                                        "run": run['3'],
                                        "crew": crew['6'],
                                    }]);
                                }
                            }
                        }}
                        className={`flex flex-row h-8 w-full border-b border-gray-200 bg-white relative`}
                    >
                        { 
                            shownColumns.map((column, index) => (
                                <Box 
                                key={index}
                                title={String(assignment?.[column?.field_id])}
                                onDoubleClick={() => {
                                    if (editableFieldIds.includes(column.field_id)) {
                                        setEditMode({ ...editMode, [column.field_id]: !editMode[column.field_id] });
                                    }
                                }}
                                sx={{
                                    borderRight: "1px solid #E5E7EB",
                                    height: "100%",
                                    minWidth: column.width,
                                    maxWidth: column.width,
                                    display: "flex",
                                    justifyContent: column.align,
                                    alignItems: "center",
                                    padding: editMode[column.field_id] ? "0px" : "0px 8px",
                                    boxSizing: "border-box",
                                    backgroundColor: assignment?.['180'] !== '' && column?.field_id === 87 ? assignment?.['180'] : backgroundColor,
                                    // overflow: "hidden",
                                    position: column.id === 1 ? "sticky" : "relative",
                                    left: column.id === 1 ? "0px" : "auto",
                                    zIndex: column.id === 1 ? "100" : "0",
                                }}
                                >
                                     { column.id !== 1 ? null : 
                                            <>
                                                <EditAssignmentButton assignment={assignment} crews={crews} setCrews={setCrews} run={run} setAlertMessage={setAlertMessage}/>
                                                <ViewAssignmentButton assignment={assignment}/>
                                                { assignment?.['102'] !== 'Survey' && (
                                                    <TransferAssignmentButton assignment={assignment} run={run} crews={crews} setCrews={setCrews} setAlertMessage={setAlertMessage} />
                                                )}
                                            </>
                                    }
                                    {editMode[column.field_id] ? (
                                        column.type === 'select' ? (
                                            <FormControl
                                                fullWidth
                                                size="small"
                                                sx={{
                                                    height: '100%',
                                                }}
                                            >
                                                <Select
                                                    id={`select-${column.field_id}`}
                                                    value={assignment?.[column.field_id]}
                                                    autoFocus
                                                    onBlur={(e) => {
                                                        const newCrews = [...crews];
                                                        const crewIndex = newCrews.findIndex(c => c['6'] === crew['6']);
                                                        const runIndex = newCrews[crewIndex]['runs'].findIndex(r => r['3'] === run['3']);
                                                        const assignmentIndex = newCrews[crewIndex]['runs'][runIndex]['assignments'].findIndex(a => a['3'] === assignment['3']);
                                                        newCrews[crewIndex]['runs'][runIndex]['assignments'][assignmentIndex][column.field_id] = e.target.value;
                                                        setCrews(newCrews);
                                                        setEditMode({ ...editMode, [column.field_id]: false });
                                                    }}
                                                    onChange={(e) => {
                                                        const newCrews = [...crews];
                                                        const crewIndex = newCrews.findIndex(c => c['6'] === crew['6']);
                                                        const runIndex = newCrews[crewIndex]['runs'].findIndex(r => r['3'] === run['3']);
                                                        const assignmentIndex = newCrews[crewIndex]['runs'][runIndex]['assignments'].findIndex(a => a['3'] === assignment['3']);
                                                        newCrews[crewIndex]['runs'][runIndex]['assignments'][assignmentIndex][column.field_id] = e.target.value;
                                                        setCrews(newCrews);
                                                        setEditMode({ ...editMode, [column.field_id]: false });
                                                    }}
                                                    sx={{
                                                        'fontSize': '12px',
                                                        'height': '100%',
                                                        '& .MuiSelect-select': {
                                                            padding: '0 0 0 8px',
                                                            // pl: '8px',
                                                            height: 'auto',
                                                        },
                                                    }}
                                                >
                                                    {/* Blank option */}
                                                    <MenuItem value="">
                                                    <em>None</em>
                                                    </MenuItem>
                                                    {employees?.map((option) => (
                                                        <MenuItem key={option?.['3']} value={option?.[21]}>{option?.[21]}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : column.type === 'text' || column.type === 'date' ? (
                                            <TextField
                                                size="small"
                                                fullWidth
                                                type={column.type}
                                                defaultValue={column.type === 'date' ? convertDate(assignment?.[column.field_id]) : assignment?.[column.field_id]}
                                                onBlur={(e) => {
                                                    const newCrews = [...crews];
                                                    const crewIndex = newCrews.findIndex(c => c['6'] === crew['6']);
                                                    const runIndex = newCrews[crewIndex]['runs'].findIndex(r => r['3'] === run['3']);
                                                    const assignmentIndex = newCrews[crewIndex]['runs'][runIndex]['assignments'].findIndex(a => a['3'] === assignment['3']);
                                                    
                                                    if (column.type === 'date') {
                                                        newCrews[crewIndex]['runs'][runIndex]['assignments'][assignmentIndex][column.field_id] = convertDateToReadable(e.target.value);
                                                    } else {
                                                        newCrews[crewIndex]['runs'][runIndex]['assignments'][assignmentIndex][column.field_id] = e.target.value;
                                                    }
                                                    setCrews(newCrews);
                                                    setEditMode({ ...editMode, [column.field_id]: false });
                                                }}
                                                autoFocus
                                                sx={{
                                                    height: '100%',
                                                    borderRadius: '0px',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                        borderRadius: '0px',
                                                    },
                                                }}
                                                inputProps={{
                                                    style: {
                                                    fontSize: '12px',
                                                    padding: '0px 0px 0px 8px',
                                                    height: '100%',
                                                    borderRadius: '0px',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Checkbox
                                                size="small"
                                                checked={assignment?.[column.field_id]}
                                                onChange={(e) => {
                                                    const newCrews = [...crews];
                                                    const crewIndex = newCrews.findIndex(c => c['6'] === crew['6']);
                                                    const runIndex = newCrews[crewIndex]['runs'].findIndex(r => r['3'] === run['3']);
                                                    const assignmentIndex = newCrews[crewIndex]['runs'][runIndex]['assignments'].findIndex(a => a['3'] === assignment['3']);
                                                    newCrews[crewIndex]['runs'][runIndex]['assignments'][assignmentIndex][column.field_id] = e.target.checked;
                                                    setCrews(newCrews);
                                                    setEditMode({ ...editMode, [column.field_id]: false });
                                                }}
                                                sx={{
                                                    color: textColor,
                                                }}
                                            />
                                        )
                                    ) : (
                                     column.type !== 'boolean' ? (
                                        <Typography
                                            sx={{
                                                fontSize: "12px", 
                                                color: assignment?.['180'] !== '' && column?.field_id === 87 ? 'rgb(51, 51, 51)' : textColor,
                                                textOverflow: "ellipsis", 
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {assignment?.[column?.field_id]}
                                        </Typography>
                                    )
                                        :
                                        <Checkbox
                                            size="small"
                                            sx={{
                                                color: textColor,
                                            }}
                                            checked={assignment?.[column?.field_id]}
                                            disabled={true}
                                        />
                                    )}
                                </Box>
                            ))
                        }
                    </div>
                )
            }}
        </Draggable>
    )
}
import { FormControl, Select, MenuItem } from '@mui/material';
export default function ModalSelect({value, options, handleChange, field}) {

  return (
    <FormControl
      fullWidth
      size="small"
    >
      <Select
        id={`select-${value}`}
        value={value ? value : ''}
        onChange={(e) => {
          handleChange(e);
        }}
        sx={{
          'maxHeight': '34px',
          'minHeight': '34px',
          'fontSize': '13px',
          'margin': '8px 0 8px',
          '& .MuiSelect-select': {
            padding: '0.5rem',
          },
        }}
      >
        {/* Blank option */}
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options?.map((option) => (
          <MenuItem key={option?.['3']} value={option?.[field]}>{option?.[field]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
import {EmployeeApi} from '../../services/EmployeeApi';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  employees: [],
};

export const EmployeeSlice = createSlice({
  name: 'employee',
  initialState: initialState,
  reducers: {
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    resetEmployeeState: () => initialState,
  },
  // The `extraReducers` field lets the slice handle actions defined in the authApi slice
  extraReducers: (builder) => {
    builder
      .addMatcher(EmployeeApi.endpoints.getEmployees.matchFulfilled, (state, {payload}) => {
        state.employees = payload;
      })
  },
});

// Action creators are generated for each case reducer function
export const {setEmployees, resetEmployeeState} = EmployeeSlice.actions;

export const selectEmployees = (state) => state.employee.employees;

export default EmployeeSlice.reducer;

import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";

export default function AlertMessage({ message, setMessage }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (message !== '') {
            setIsVisible(true); // Show the alert
            const timer = setTimeout(() => {
                setIsVisible(false); // Hide the alert after 5 seconds
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [message, setMessage]);

    useEffect(() => {
        if (!isVisible) {
            const timer = setTimeout(() => {
                setMessage(""); // Clear message after transition
            }, 500); // This should match the CSS transition time
            return () => clearTimeout(timer);
        }
    }, [isVisible, setMessage]);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: isVisible ? "48px" : "-70px", // Use isVisible to control position
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: '180px',
                px: 2,
                height: '42px',
                zIndex: 9999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: "column",
                backgroundColor: "rgb(60, 60, 60)",
                borderRadius: "6px",
                transition: "bottom 0.5s ease-in-out"
            }}
        >
            <Typography sx={{ fontSize: "13px", color: "#fff" }}>{message}</Typography>
        </Box>
    )
}

import ModalHeader from "./ModalHeader";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useTransferAssignmentMutation } from "../../../../services/AssignmentApi";

export default function TransferAssignmentModal({handleClose, assignment, crews, setCrews, run, setAlertMessage}) {
    const [disabled, setDisabled] = useState(false);
    const [transferAssignment] = useTransferAssignmentMutation();
    return (
        <div 
            className='flex flex-col w-full h-full pb-2'
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <ModalHeader handleClose={handleClose} message={`Transfer this record to survey team: ${assignment['46']}`} />

            {/* Confirmation message */}
            <div className="flex flex-col px-6 py-6">
                <Typography sx={{fontSize: '16px', fontWeight: '600', color: '#DC053C', width: '100%', textAlign: 'center'}}>Are you sure you want to transfer this record?</Typography>
            </div>

            <div className="flex flex-row px-6 pb-2 mt-4">
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                    sx={{
                        'textTransform': 'none',
                        'fontSize': '13px',
                        'color': 'rgb(51, 51, 51)',
                        'backgroundColor':'#fff',
                        'letterSpacing': 'normal',
                        'ml': '0rem',
                        '&:hover': {backgroundColor: '#fff', filter: 'brightness(0.95)'},
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        setDisabled(true);
                        transferAssignment({
                            id: assignment?.['3'],
                            data: {'transfer': false}
                        })
                        .unwrap()
                        .then((result) => {
                            console.log("Result", result);
                            // Remove the assignment from the run
                            const updatedRun = {...run};
                            const updatedAssignments = [...updatedRun['assignments']];
                            const assignmentIdx = updatedAssignments.findIndex((a) => a['3'] === assignment['3']);
                            updatedAssignments.splice(assignmentIdx, 1);
                            updatedRun['assignments'] = updatedAssignments;

                            // Find the crew which satisfies the condition:
                            // run['11'] === crew['6']
                            const crew = crews.find((crew) => crew['6'] === run['11']);
                            // Update the crew's runs array with the new run
                            const newCrew = {...crew};
                            newCrew['runs'] = [...crew['runs']];
                            const runIdx = newCrew['runs'].findIndex((run) => run['3'] === updatedRun['3']);
                            newCrew['runs'][runIdx] = updatedRun;
                            // Update the crews array with the new crew
                            const newCrews = [...crews];
                            const crewIdx = newCrews.findIndex((crew) => crew['6'] === newCrew['6']);
                            newCrews[crewIdx] = newCrew;
                            // Update the crews state
                            setCrews(newCrews);
                            setAlertMessage("Assignment transferred successfully!");
                            handleClose();
                        })
                        .catch((err) => {
                            console.log("Error", err);
                        })
                        .finally(() => {
                            setDisabled(false);
                        });
                    }}
                    disabled={disabled}
                    sx={{
                        'textTransform': 'none',
                        'fontSize': '13px',
                        'color': '#fff',
                        'backgroundColor': '#4473D2',
                        'letterSpacing': 'normal',
                        'ml': 'auto',
                        'width': '142px',
                        '&:hover': {backgroundColor: '#4473D2', filter: 'brightness(0.95)'},
                    }}
                >
                    Yes, I'm sure.
                </Button>
            </div>
    
        </div>
    )
}
import ModalHeader from "../ModalHeader";
import Buttons from "./buttons/Buttons";
import ModalSelect from './inputs/ModalSelect';
import ModalDateField from './inputs/ModalDateField';
import ModalCheckbox from "./inputs/ModalCheckbox";
import CustomMultiline from "../../inputs/CustomMultiline";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useGetEmployeesQuery } from "../../../../../services/EmployeeApi";
import { useEditAssignmentMutation } from "../../../../../services/AssignmentApi";

// Convert date from "MM/DD/YYYY" to "YYYY-MM-DD"
const convertDate = (date) => {
  const splitDate = date.split('/');
  const year = splitDate[2];
  const month = splitDate[0];
  const day = splitDate[1];
  return `${year}-${month}-${day}`;
}

// Convert date from "YYYY-MM-DD" to "MM/DD/YYYY"
const convertDateToReadable = (date) => {
  const splitDate = date.split('-');
  const year = splitDate[0];
  const month = splitDate[1];
  const day = splitDate[2];
  return `${month}/${day}/${year}`;
}

export default function EditAssignmentModal({handleClose, assignment, crews, setCrews, run, setAlertMessage}) {
  const [editAssignment] = useEditAssignmentMutation();
  const {data: employees, isLoading, isFetching} = useGetEmployeesQuery();
  const [startDate, setStartDate] = useState(assignment?.['47'] ? convertDate(assignment?.['47']) : '');
  const [endDate, setEndDate] = useState(assignment?.['48'] ? convertDate(assignment?.['48']) : '');
  const [siteContactNotified, setSiteContactNotified] = useState(assignment?.['128']);
  const [siteContactReplied, setSiteContactReplied] = useState(assignment?.['129']);
  const [pendingApproval, setPendingApproval] = useState(assignment?.['173']);
  const [crewManager, setCrewManager] = useState(assignment?.['60']);
  const [srCrewManager, setSrCrewManager] = useState(assignment?.['189']);
  const [schedulingNotes, setSchedulingNotes] = useState(assignment?.['187']);
  const [crewLeftSite, setCrewLeftSite] = useState(assignment?.['57']);

  if (isLoading || isFetching) {
    return (
      <div className='flex flex-col w-full h-full pb-2'>
              <ModalHeader handleClose={handleClose} message={`Assignment #${assignment?.['46']}`} />
              <Typography sx={{fontSize: '12px'}}>Loading...</Typography>
      </div>
    )
  }

  return (
    <div 
      className='flex flex-col w-full h-full pb-2'
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
        <ModalHeader handleClose={handleClose} message={`Assignment #${assignment?.['46']}`} />

        <div className="flex flex-col px-6 pt-4">

          <div className="flex flex-col items-start">
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", mr: "1rem"}}>Crew manager</Typography>
            <ModalSelect 
                value={crewManager} 
                options={isLoading || isFetching ? [] : employees}
                handleChange={(e) => {
                  setCrewManager(e.target.value);
                }}
                field={'21'}
            />
          </div>

          <div className="flex flex-col items-start">
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", mr: "1rem"}}>Sr. Crew manager</Typography>
            <ModalSelect 
                value={srCrewManager} 
                options={isLoading || isFetching ? [] : employees}
                handleChange={(e) => {
                  setSrCrewManager(e.target.value);
                }}
                field={'21'}
            />
          </div>

          <div className="mt-2 flex flex-col items-start">
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", mr: "1rem"}}>Start date</Typography>
            <ModalDateField 
                value={startDate}
                handleChange={(e) => {
                  setStartDate(e.target.value);
                }}
            />
          </div>

          <div className="mt-2 flex flex-col items-start">
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", mr: "1rem"}}>End date</Typography>
            <ModalDateField 
                value={endDate} 
                handleChange={(e) => {
                  setEndDate(e.target.value);
                }}
            />
          </div>

          <div className="mt-2 flex flex-col items-start">
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", mr: "1rem"}}>Scheduling Notes</Typography>
            <CustomMultiline
              minRows={3}
              placeholder="Notes"
              value={schedulingNotes}
              onChange={(event) => {
                  setSchedulingNotes(event.target.value);
              }}
            />
          </div>

          <div className="mt-2 flex flex-row items-center">
            <ModalCheckbox 
                value={siteContactNotified ?? false} 
                handleChange={(e) => {
                  setSiteContactNotified(e.target.checked);
                }}
            />
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", ml: "0.5rem"}}>Site contact contacted</Typography>
          </div>

          <div className="mt-2 flex flex-row items-center">
            <ModalCheckbox 
                value={siteContactReplied ?? false} 
                handleChange={(e) => {
                  setSiteContactReplied(e.target.checked);
                }}
            />
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", ml: "0.5rem"}}>Site contact replied</Typography>
          </div>

          <div className="mt-2 flex flex-row items-center">
            <ModalCheckbox 
                value={pendingApproval ?? false} 
                handleChange={(e) => {
                  setPendingApproval(e.target.checked);
                }}
            />
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", ml: "0.5rem"}}>Pending approval</Typography>
          </div>

          <div className="mt-2 flex flex-row items-center">
            <ModalCheckbox 
                value={crewLeftSite ?? false} 
                handleChange={(e) => {
                  setCrewLeftSite(e.target.checked);
                }}
            />
            <Typography sx={{fontSize: "12px", color: "rgb(51, 51, 51)", ml: "0.5rem"}}>Crew left site</Typography>
          </div>

        </div>

        <Buttons 
          handleClose={handleClose}
          submit={() => {
            editAssignment({
                id: assignment?.['3'],
                data: {'start_date': startDate, 'end_date': endDate, 'crew_manager': crewManager === '' ? null : crewManager, 'site_contact_notified': siteContactNotified, 'site_contact_replied': siteContactReplied, 'pending_approval': pendingApproval, 'scheduling_notes': schedulingNotes, 'sr_crew_manager': srCrewManager, 'crew_left_site': crewLeftSite}
            })
            .unwrap()
            .then((res) => {
                console.log(res);
                const newAssignment = {...assignment};
                newAssignment['47'] = startDate === '' ? '' : convertDateToReadable(startDate);
                newAssignment['48'] = endDate === '' ? '' : convertDateToReadable(endDate);
                newAssignment['128'] = siteContactNotified;
                newAssignment['129'] = siteContactReplied;
                newAssignment['173'] = pendingApproval;
                newAssignment['57'] = crewLeftSite;
                newAssignment['60'] = crewManager;
                newAssignment['187'] = schedulingNotes;
                newAssignment['189'] = srCrewManager;

                // Update the assignment in the run
                const updatedRun = {...run};
                const updatedAssignments = [...updatedRun['assignments']];
                const index = updatedAssignments.findIndex((assignment) => assignment['3'] === newAssignment['3']);
                updatedAssignments[index] = newAssignment;
                updatedRun['assignments'] = updatedAssignments;
                
                // Find the crew which satisfies the condition:
                // run['11'] === crew['6']
                const crew = crews.find((crew) => crew['6'] === run['11']);
                // Update the crew's runs array with the new run
                const newCrew = {...crew};
                newCrew['runs'] = [...crew['runs']];
                const runIdx = newCrew['runs'].findIndex((run) => run['3'] === updatedRun['3']);
                newCrew['runs'][runIdx] = updatedRun;
                // Update the crews array with the new crew
                const newCrews = [...crews];
                const crewIdx = newCrews.findIndex((crew) => crew['6'] === newCrew['6']);
                newCrews[crewIdx] = newCrew;
                // Update the crews state
                setCrews(newCrews);
                setAlertMessage('Assignment updated successfully!');
                handleClose();
            })
            .catch((err) => {
                console.log(err);
            })
        }}
        />
    </div>
  );
}
import { Box, Typography } from "@mui/material"
import ColumnFilter from "./inputs/ColumnFilter"

export default function Columns({showFilters, setFilters, filters, columns, width}) {
    const shownColumns = columns.filter((column) => column.shown);
    return (
        <div style={{width: width + 'px'}} className={`inline-flex bg-blue-500 ${!showFilters ? 'min-h-[32px]' : 'min-h-[80px]'} items-center z-[500] sticky top-0`}>
            { shownColumns.map((column) => {
                return (
                    <Box 
                        key={column.field_id}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            position: column.id === 1 ? "sticky" : "relative",
                            left: column.id === 1 ? "0px" : "auto",
                            zIndex: column.id === 1 ? "100" : "0",
                        }}
                    >
                        <Box 
                            sx={{
                                width: `${column.width}px`,
                                backgroundColor: "#F9FAFB",
                                flex: 1,
                                display: "flex",
                                maxHeight: "32px",
                                justifyContent: column.align,
                                alignItems: "center",
                                padding: "0px 8px",
                                boxSizing: "border-box",
                                borderRight: "1px solid #E5E7EB",
                                borderTop: "1px solid #E5E7EB",
                                borderBottom: showFilters ? "1px solid #E5E7EB" : "none",
                            }}
                        >
                            <Typography sx={{ fontSize: "12px", color: "rgb(51, 51, 51)" }}>{column.label}</Typography>
                        </Box>
                        {showFilters ?
                        <Box
                            sx={{
                                width: `${column.width}px`,
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                padding: "0px 8px",
                                boxSizing: "border-box",
                                border: "none",
                            }}
                        >
                            <ColumnFilter column={column} filters={filters} setFilters={setFilters} />
                        </Box>
                        : null}
                    </Box>
            )})}
        </div>
    )
}
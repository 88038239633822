import { Typography } from "@mui/material"

export default function Crew({crew}) {
    return (
        <div className="w-full p-2 bg-[#00263a] text-white relative flex flex-row items-center">
            <div className="sticky left-2 flex flex-row items-center max-w-[100vw]">
                <Typography sx={{fontWeight: "500", whiteSpace: 'nowrap'}}>
                    {crew?.['6']}
                </Typography>
            </div>
        </div>
    )
}
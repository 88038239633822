import {Button} from '@mui/material';
import { useState } from 'react';
export default function SaveButton({submit}) {
  const [disabled, setDisabled] = useState(false);
  return (
    <Button
        onClick={() => {
            setDisabled(true);
            submit();
        }}
        disabled={disabled}
        sx={{
            'textTransform': 'none',
            'fontSize': '13px',
            'color': '#fff',
            'backgroundColor': '#4473D2',
            'letterSpacing': 'normal',
            'ml': 'auto',
            'width': '142px',
            '&:hover': {backgroundColor: '#4473D2', filter: 'brightness(0.95)'},
        }}
    >
      Save Changes
    </Button>
  );
}
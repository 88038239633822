import { TextField, Chip, Autocomplete, MenuItem } from "@mui/material"

export default function Searchbar({searchTerm, setSearchTerm, searchShown, crews}) {

    const options = crews.map(crew => crew?.['6']);

    return (
        <div className={`min-w-[270px] flex justify-center items-center h-full mr-2 ml-auto transition-all`}>
            { searchShown ?
                <Autocomplete
                    multiple
                    value={searchTerm}
                    size={"small"}
                    limitTags={2}
                    options={options}
                    onChange={(event, values) => {
                        setSearchTerm(values);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    renderOption={(props, option, { selected }) => {
                        const { key, ...menuItemProps } = props;
                        return (
                            <MenuItem
                                key={key}
                                {...menuItemProps}
                                value={option}
                                sx={{fontSize: '12px', fontWeight: '400'}}
                            >
                                {option}
                            </MenuItem>
                        );
                    }}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                        <Chip 
                            {...getTagProps({ index })} 
                            key={option} 
                            label={option} 
                            size="small"
                        />
                        ))
                    }}
                    renderInput={(params) => (
                    <TextField 
                        placeholder={'Employee...'}
                        sx={{
                        '& .MuiFormLabel-root': {
                            fontSize: '12px !important',
                        },
                        }}
                        {...params}
                    />
                    )}
                    sx={{
                        width: '100%',
                    }}
                />
                :
                null
            }
        </div>
    )
}
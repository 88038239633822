import Assignment from "./Assignment";
import Columns from "./Columns";
import TableHeader from "./tables/TableHeader";
import { useState, useEffect } from "react";
import { useFilters } from "../hooks/useFilters";
import { useSearch } from "../hooks/useSearch";
import { useColumns } from "../hooks/useColumns";
import { useLoading } from "../hooks/useLoading";
import { useSelectedItems } from "../hooks/useSelectedItems";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AssignmentPlaceHolder from "./placeholders/AssignmentPlaceholder";
import CrewPlaceholder from "./placeholders/CrewPlaceholder";
import AlertMessage from "./popups/AlertMessage";
import Loading from "../../shared/components/Loading";
import ShowUnassignedButton from "./buttons/ShowUnassignedButton";
import Unassigned from "./Unassigned";
import Crew from "./Crew";
import UnassignedHeader from "./tables/UnassignedHeader";
import { handleDrop } from "../hooks/handleDrop";
// import AddFieldProfessionalButton from "./buttons/AddFieldProfessionalButton";

export default function Assessment() {
    const { showFilters, toggleFilters, filters, setFilters } = useFilters();
    const { searchTerm, setSearchTerm, searchShown, setSearchShown } = useSearch();
    const { columns, setColumns, totalWidth } = useColumns();
    const { crewsLoading, setCrewsLoading, unassignedLoading, setUnassignedLoading } = useLoading();
    const { selectedItems, setSelectedItems } = useSelectedItems();
    const [crews, setCrews] = useState([]);
    const [unassignedRecords, setUnassignedRecords] = useState([]);
    const [showUnassigned, setShowUnassigned] = useState(false);
    const [saving, setSaving] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        setCrewsLoading(true);
        setUnassignedLoading(true);

        async function fetchCrews() {
            try {
                const BASE_URL = process.env.REACT_APP_API;
                const response = await fetch(`${BASE_URL}/field-professionals/list`);
                const data = await response.json();
                setCrews(data);
            } catch (error) {
                console.error('Failed to fetch crews', error);
            } finally {
                setCrewsLoading(false);
            }
        }
    
        async function fetchUnassigned() {
            try {
                const BASE_URL = process.env.REACT_APP_API;
                const response = await fetch(`${BASE_URL}/assignments/list/62`);
                const data = await response.json();
                setUnassignedRecords(data);
            } catch (error) {
                console.error('Failed to fetch unassigned', error);
            } finally {
                setUnassignedLoading(false);
            }
        }

        fetchCrews();
        fetchUnassigned();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this effect runs once on component mount

    if (crewsLoading || unassignedLoading || saving) return <Loading loading={crewsLoading || unassignedLoading || saving} />;

    const filteredCrews = crews.filter((crew) => {
        // No active search filter
        if (searchTerm === "") return true;
        return crew?.['6'].toLowerCase().includes(searchTerm.toLowerCase());
    })

    return (
        <DragDropContext onDragEnd={(result) => {handleDrop(result, selectedItems, setSelectedItems, crews, setCrews, unassignedRecords, setUnassignedRecords)}}>
            {/* Parent Container */}
            <div className={`absolute top-0 bottom-0 left-0 right-0 mt-12 ${showUnassigned ? 'mb-[340px]' : 'mb-0'}`}>

                {/* Table Header */}
                <TableHeader crews={crews} showFilters={showFilters} toggleFilters={toggleFilters} searchShown={searchShown} setSearchShown={setSearchShown} searchTerm={searchTerm} setSearchTerm={setSearchTerm} columns={columns} setColumns={setColumns} setAlertMessage={setAlertMessage} setSaving={setSaving} unassignedRecords={unassignedRecords} />

                {/* Records */}
                <div className="bg-white flex flex-row h-full box-border">

                    {/* Assigned Records */}
                    <div className="overflow-auto flex flex-col relative">

                        {/* Columns */}
                        <Columns width={totalWidth} showFilters={showFilters} filters={filters} setFilters={setFilters} columns={columns} />

                        {/* Table Body */}
                        <div style={{width: totalWidth + 'px'}}>
                            {
                                filteredCrews.length === 0 ?
                                    <CrewPlaceholder />
                                :
                                filteredCrews?.map((crew, idx) => (
                                    <div className="flex flex-col" key={idx}>
                                        <Crew crew={crew} />
                                        <Droppable droppableId={String(crew?.['3'])} key={crew?.['3']}>
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        className="flex flex-row w-full flex-wrap bg-white border-t border-gray-200"
                                                    >
                                                        <Assignments crew={crew} columns={columns} filters={filters} selectedItems={selectedItems} setSelectedItems={setSelectedItems} crews={crews} setCrews={setCrews} setAlertMessage={setAlertMessage}/>
                                                        {provided.placeholder}
                                                    </div>
                                                )
                                            }}
                                        </Droppable>
                                    </div>
                                ))
                            }
                        </div>

                        { !showUnassigned && (
                            <ShowUnassignedButton showUnassigned={showUnassigned} setShowUnassigned={setShowUnassigned} />
                        )}
                        {/* { !showUnassigned && (
                            <AddFieldProfessionalButton crews={crews} setCrews={setCrews} setAlertMessage={setAlertMessage} />
                        )} */}
                        <AlertMessage message={alertMessage} setMessage={setAlertMessage} />

                    </div>

                </div>

                {/* Unassigned Records */}
                <div className={`${!showUnassigned ? 'hidden' : 'fixed bottom-0 left-0 flex flex-col'} h-[340px] w-full overflow-auto box-border border-t border-gray-200`}>
                    
                    <UnassignedHeader setShowUnassigned={setShowUnassigned} />
                    <Unassigned unassignedRecords={unassignedRecords} setUnassignedRecords={setUnassignedRecords} filters={filters} setAlertMessage={setAlertMessage} />

                </div>

            </div>
        </DragDropContext>
    )
}

function Assignments({crew, columns, filters, selectedItems, setSelectedItems, crews, setCrews, setAlertMessage}) {

    const filteredAssignments = (crew) => {
        // If each filter object's value property within filter is empty, return the assignment
        if (filters.every((filter) => filter.value === "")) return crew.assignments || [];
        // Else, filter all properties on the assignment whose matching filter value is not empty
        return crew.assignments.filter((assignment) => {
            return filters.every((filter) => {
                if (filter.value === "") return true; // Skip empty filters
                const assignmentValue = assignment[filter.field_id];
                if (typeof assignmentValue === 'string') {
                    return assignmentValue.toLowerCase().includes(filter.value.toLowerCase());
                } 
                if (typeof assignmentValue === 'number') {
                    return assignmentValue.toString().includes(filter.value);
                }
                return true; // or some other default behavior for unsupported types
            });
        });
    }

    if (!crew.assignments || filteredAssignments(crew).length === 0) return (
        <AssignmentPlaceHolder />
    )

    return (
        filteredAssignments(crew).map((assignment, idx) => (
            <Assignment key={assignment?.['3']} assignment={assignment} columns={columns} index={idx} selectedItems={selectedItems} setSelectedItems={setSelectedItems} crews={crews} setCrews={setCrews} crew={crew} setAlertMessage={setAlertMessage}/>
        ))
    )
}
import IconButtonBase from "./IconButtonBase";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffOutlinedIcon from '@mui/icons-material/FilterListOffOutlined';

export default function ToggleFilterButton({showFilters, toggleFilters}) {

    return (
        <IconButtonBase
            handleClick={toggleFilters}
            tooltip="Toggle Filters"
        >
            { showFilters ?
                <FilterListIcon 
                    sx={{
                        color: "rgb(51, 51, 51)",
                        fontSize: "18px"
                    }}
                />
                :
                <FilterListOffOutlinedIcon
                    sx={{
                        color: "rgb(51, 51, 51)",
                        fontSize: "18px"
                    }}
                />
            }
        </IconButtonBase>
    )
}
import {Button} from '@mui/material';
import { useScheduleSubcontractorsMutation, useScheduleUnassignedMutation } from '../../../../services/SchedulerApi';
export default function SubmitButton({crews, setAlertMessage, setSaving, unassignedRecords}) {
  const [scheduleSubcontractors] = useScheduleSubcontractorsMutation();
  const [scheduleUnassigned] = useScheduleUnassignedMutation();

  return (
    <Button
        onClick={() => {
            setSaving(true);

            Promise.all([
              scheduleSubcontractors(crews).unwrap(),
              scheduleUnassigned(unassignedRecords).unwrap()
            ])
            .then(([scheduleResult, unassignedResult]) => {
                console.log("schedule result", scheduleResult);
                console.log("Unassigned result", unassignedResult);
                setAlertMessage("Changes saved successfully!");
            })
            .catch((err) => {
                console.log("Error", err);
                setAlertMessage("Error saving changes");
            })
            .finally(() => {
                setSaving(false);
            });

        }}
        sx={{
            'textTransform': 'none',
            'fontSize': '13px',
            'color': '#fff',
            'backgroundColor': '#4473D2',
            'letterSpacing': 'normal',
            'ml': 1,
            'mr': 1,
            'width': '142px',
            '&:hover': {backgroundColor: '#4473D2', filter: 'brightness(0.95)'},
        }}
    >
      Save Changes
    </Button>
  );
}
import {AssignmentApi} from '../../services/AssignmentApi';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  assignment: {},
  unassigned: [],
};

export const AssignmentSlice = createSlice({
  name: 'assignment',
  initialState: initialState,
  reducers: {
    setAssignment: (state, action) => {
      state.assignment = action.payload;
    },
    setUnassigned: (state, action) => {
        state.unassigned = action.payload;
    },
    resetAssignmentState: () => initialState,
  },
  // The `extraReducers` field lets the slice handle actions defined in the authApi slice
  extraReducers: (builder) => {
    builder
      .addMatcher(AssignmentApi.endpoints.getAssignment.matchFulfilled, (state, {payload}) => {
        state.assignment = payload.data;
      })
      .addMatcher(AssignmentApi.endpoints.getUnassigned.matchFulfilled, (state, {payload}) => {
        state.unassigned = payload.data;
      })
  },
});

// Action creators are generated for each case reducer function
export const {setAssignment, resetAssignmentState} = AssignmentSlice.actions;

export const selectAssignment = (state) => state.assignment.assignment;
export const selectUnassigned = (state) => state.assignment.unassigned;

export default AssignmentSlice.reducer;

import Assignment from "./Assignment";
import Columns from "./Columns";
import TableHeader from "./tables/TableHeader";
import { useState, useEffect } from "react";
import { useFilters } from "../hooks/useFilters";
import { useSearch } from "../hooks/useSearch";
import { useColumns } from "../hooks/useColumns";
import { useLoading } from "../hooks/useLoading";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AssignmentPlaceHolder from "./placeholders/AssignmentPlaceholder";
import RunPlaceholder from "./placeholders/RunPlaceholder";
import CrewPlaceholder from "./placeholders/CrewPlaceholder";
import AlertMessage from "./popups/AlertMessage";
import Saving from "./popups/Saving";
import ModalBase from "./popups/ModalBase";
import Loading from "../../shared/components/Loading";
import ShowUnassignedButton from "./buttons/ShowUnassignedButton";
import Run from "./Run";
import Unassigned from "./Unassigned";
import Crew from "./Crew";
import UnassignedHeader from "./tables/UnassignedHeader";
import { handleDrop } from "../hooks/handleDrop";
import ShowTransfersButton from "./buttons/ShowTransfersButton";
import TransferHeader from "./tables/TransferHeader";
import Transfers from "./Transfers";

export default function Survey() {
    const { showFilters, toggleFilters, filters, setFilters } = useFilters();
    const { searchTerm, setSearchTerm, searchShown, setSearchShown } = useSearch();
    const { columns, setColumns, totalWidth } = useColumns();
    const { crewsLoading, setCrewsLoading, unassignedLoading, setUnassignedLoading, transfersLoading, setTransfersLoading } = useLoading();
    const [crews, setCrews] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [unassignedRecords, setUnassignedRecords] = useState([]);
    const [transferRecords, setTransferRecords] = useState([]);
    const [showUnassigned, setShowUnassigned] = useState(false);
    const [showTransfers, setShowTransfers] = useState(false);
    const [saving, setSaving] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (unsavedChanges) {
                // Prevent the window from closing
                event.preventDefault();
                // Chrome requires returnValue to be set
                event.returnValue = '';
            }
        };

        // Add event listener
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges]);

    useEffect(() => {
        setCrewsLoading(true);
        setUnassignedLoading(true);

        async function fetchCrews() {
            try {
                const BASE_URL = process.env.REACT_APP_API;
                const response = await fetch(`${BASE_URL}/runs/list`);
                const data = await response.json();
                setCrews(data);
            } catch (error) {
                console.error('Failed to fetch crews', error);
            } finally {
                setCrewsLoading(false);
            }
        }

        async function fetchUnassigned() {
            try {
                const BASE_URL = process.env.REACT_APP_API;
                const response = await fetch(`${BASE_URL}/assignments/list/59`);
                const data = await response.json();
                setUnassignedRecords(data);
            } catch (error) {
                console.error('Failed to fetch unassigned', error);
            } finally {
                setUnassignedLoading(false);
            }
        }

        async function fetchTransfers() {
            try {
                const BASE_URL = process.env.REACT_APP_API;
                const response = await fetch(`${BASE_URL}/assignments/list/60`);
                const data = await response.json();
                setTransferRecords(data);
            } catch (error) {
                console.error('Failed to fetch unassigned', error);
            } finally {
                setTransfersLoading(false);
            }
        }

        async function fetchEmployees() {
            try {
                const BASE_URL = process.env.REACT_APP_API;
                const response = await fetch(`${BASE_URL}/employees/list`);
                const data = await response.json();
                setEmployees(data);
            } catch (error) {
                console.error('Failed to fetch employees', error);
            }
        }

        fetchCrews();
        fetchUnassigned();
        fetchTransfers();
        fetchEmployees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array means this effect runs once on component mount

    if (crewsLoading || unassignedLoading || transfersLoading) return <Loading loading={crewsLoading || unassignedLoading || transfersLoading} />;

    const filteredCrews = crews.filter((crew) => {
        // If the array is empty, return all crews
        if (searchTerm.length === 0) return true;
        // If the array is not empty, check if any of the search terms include the crew name
        return searchTerm.some((term) => crew?.['6'].toLowerCase().includes(term.toLowerCase()));
    })

    return (
        <DragDropContext onDragEnd={(result) => { handleDrop(result, selectedItems, setSelectedItems, crews, setCrews, unassignedRecords, setUnassignedRecords, transferRecords, setTransferRecords, setUnsavedChanges) }}>
            {/* Parent Container */}
            <div className={`absolute top-0 bottom-0 left-0 right-0 mt-12 ${showUnassigned || showTransfers ? 'mb-[340px]' : 'mb-0'}`}>

                {/* Table Header */}
                <TableHeader crews={crews} unassignedRecords={unassignedRecords} transferRecords={transferRecords} showFilters={showFilters} toggleFilters={toggleFilters} searchShown={searchShown} setSearchShown={setSearchShown} searchTerm={searchTerm} setSearchTerm={setSearchTerm} columns={columns} setColumns={setColumns} setAlertMessage={setAlertMessage} setSaving={setSaving} setUnsavedChanges={setUnsavedChanges} />

                {/* Records */}
                <div className="bg-white flex flex-row h-full box-border">

                    {/* Assigned Records */}
                    <div className="overflow-auto flex flex-col relative">

                        {/* Columns */}
                        <Columns width={totalWidth} showFilters={showFilters} filters={filters} setFilters={setFilters} columns={columns} />

                        {/* Table Body */}
                        <div style={{ width: totalWidth + 'px' }}>
                            {
                                filteredCrews.length === 0 ?
                                    <CrewPlaceholder />
                                    :
                                    filteredCrews?.map((crew, idx) => (
                                        <div key={idx}>
                                            <Crew crew={crew} />
                                            {crew.runs?.length === 0 ?
                                                <RunPlaceholder />
                                                :
                                                crew.runs?.map((run, idx) => (
                                                    <div className="flex flex-col" key={idx}>
                                                        <Run run={run} crews={crews} setCrews={setCrews} setAlertMessage={setAlertMessage} />
                                                        <Droppable droppableId={String(run?.['3']).concat(`-${run?.['11']}`)} key={run?.['3']}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                        className="flex flex-row w-full flex-wrap bg-white border-t border-gray-200"
                                                                    >
                                                                        <Assignments employees={employees} crew={crew} run={run} columns={columns} filters={filters} selectedItems={selectedItems} setSelectedItems={setSelectedItems} crews={crews} setCrews={setCrews} setAlertMessage={setAlertMessage} />
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )
                                                            }}
                                                        </Droppable>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                            }
                        </div>

                        {!showUnassigned && !showTransfers && (
                            <ShowUnassignedButton showUnassigned={showUnassigned} setShowUnassigned={setShowUnassigned} />
                        )}

                        {!showUnassigned && !showTransfers && (
                            <ShowTransfersButton showTransfers={showTransfers} setShowTransfers={setShowTransfers} />
                        )}
                        <AlertMessage message={alertMessage} setMessage={setAlertMessage} />

                    </div>

                </div>

                {/* Unassigned Records */}
                <div className={`${!showUnassigned ? 'hidden' : 'fixed bottom-0 left-0 flex flex-col'} h-[340px] max-w-full overflow-auto box-border border-t border-gray-200`}>
                    <UnassignedHeader setShowUnassigned={setShowUnassigned} />
                    <Unassigned unassignedRecords={unassignedRecords} setUnassignedRecords={setUnassignedRecords} filters={filters} setAlertMessage={setAlertMessage} />
                </div>

                {/* Transfer Records */}
                <div className={`${!showTransfers ? 'hidden' : 'fixed bottom-0 left-0 flex flex-col'} h-[340px] max-w-full overflow-y-auto box-border border-t border-gray-200`}>
                    <TransferHeader setShowTransfers={setShowTransfers} />
                    <Transfers transferRecords={transferRecords} filters={filters} />
                </div>
                {saving && (<ModalBase
                    minWidth={'720px !important'}
                    open={saving}
                >
                    <Saving />
                </ModalBase>)}
            </div>
        </DragDropContext>
    )
}

function Assignments({ crew, run, columns, filters, selectedItems, setSelectedItems, crews, setCrews, setAlertMessage, employees }) {

    const filteredAssignments = (run) => {
        // If each filter object's value property within filter is empty, return the assignment
        if (filters.every((filter) => filter.value === "")) return run.assignments || [];
        // Else, filter all properties on the assignment whose matching filter value is not empty
        return run.assignments.filter((assignment) => {
            return filters.every((filter) => {
                if (filter.value === "") return true; // Skip empty filters
                const assignmentValue = assignment[filter.field_id];
                if (typeof assignmentValue === 'string') {
                    return assignmentValue.toLowerCase().includes(filter.value.toLowerCase());
                }
                if (typeof assignmentValue === 'number') {
                    return assignmentValue.toString().includes(filter.value);
                }
                return true; // or some other default behavior for unsupported types
            });
        });
    }

    if (!run.assignments || filteredAssignments(run).length === 0) return (
        <AssignmentPlaceHolder />
    )

    return (
        filteredAssignments(run).map((assignment, idx) => (
            <Assignment key={assignment?.['3']} employees={employees} crew={crew} assignment={assignment} columns={columns} index={idx} selectedItems={selectedItems} setSelectedItems={setSelectedItems} crews={crews} setCrews={setCrews} run={run} setAlertMessage={setAlertMessage} />
        ))
    )
}
import { IconButton } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useState } from "react";
import EditAssignmentModal from "../popups/EditAssignmentModal/EditAssignmentModal";
import ModalBase from "../popups/ModalBase";

export default function EditAssignmentButton({assignment, run, crews, setCrews, setAlertMessage}) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <IconButton 
                size="small" 
                sx={{color: '#1976d2'}}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                }}
            >
                <EditOutlinedIcon sx={{fontSize: "14px"}}/>
            </IconButton>
            {open && (
                <ModalBase 
                    minWidth={'720px !important'}
                    open={open}
                    handleClose={() => {
                        setOpen(false);
                    }}
                >
                    <EditAssignmentModal assignment={assignment} handleClose={() => {setOpen(false)}} crews={crews} setCrews={setCrews} run={run} setAlertMessage={setAlertMessage}/>
                </ModalBase>
            )}
        </>
    )
}
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  columns: [
    {
      id: 1,
      field_id: 46,
      label: 'Job #',
      width: 150,
      primary_key: true,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 2,
      field_id: 104,
      label: 'Job Status',
      width: 150,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 3,
      field_id: 86,
      label: 'State',
      width: 150,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 4,
      field_id: 87,
      label: 'City',
      width: 200,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 5,
      field_id: 64,
      label: 'Price',
      width: 200,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 6,
      field_id: 100,
      label: 'ALTA Major Client',
      width: 300,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 7,
      field_id: 61,
      label: 'Report Type',
      width: 150,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 8,
      field_id: 55,
      label: 'Hard Dates',
      width: 150,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 9,
      field_id: 52,
      label: 'Revisit Reason',
      width: 200,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 10,
      field_id: 101,
      label: 'Blew Ref #',
      width: 200,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 11,
      field_id: 102,
      label: 'Project #',
      width: 200,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 12,
      field_id: 65,
      label: 'Acreage',
      width: 100,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 13,
      field_id: 103,
      label: 'Address',
      width: 350,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 14,
      field_id: 66,
      label: 'Due Date',
      width: 150,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 15,
      field_id: 105,
      label: 'Title',
      width: 100,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 16,
      field_id: 68,
      label: 'Start Date',
      width: 150,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 17,
      field_id: 107,
      label: 'End Date',
      width: 200,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
    {
      id: 18,
      field_id: 60,
      label: 'Crew MNGR',
      width: 200,
      primary_key: false,
      shown: true,
      align: 'start',
      sticky: false,
    },
  ],
};

export const ColumnSlice = createSlice({
  name: 'column',
  initialState: initialState,
  reducers: {
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    resetColumnState: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {setColumns, resetColumnState} = ColumnSlice.actions;

export const selectColumns = (state) => state.column.columns;

export default ColumnSlice.reducer;

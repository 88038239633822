import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import getCookie from './CSRF';

const BASE_URL = process.env.REACT_APP_API;

const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken')
};

export const AssignmentApi = createApi({
  reducerPath: 'AssignmentApi',
  tagTypes: ['Assignments, Assignment', 'Unassigned'],
  baseQuery: fetchBaseQuery({baseUrl: `${BASE_URL}/assignments`}),
  endpoints: (builder) => ({
    getAssignment: builder.query({
      query: (assignment) => `/${assignment}/get`,
      onQueryStarted: () => {
        console.log('getAssignment query started');
      },
      providesTags: ['Assignment'],
    }),
    getCrewAssignments: builder.query({
      query: (crew) => `/${crew.id}/list`,
      onQueryStarted: () => {
        console.log('getCrewAssignments query started');
      },
    }),
    getUnassigned: builder.query({
      query: () => `/unassigned/list`,
      onQueryStarted: () => {
        console.log('getUnassigned query started');
      },
      providesTags: ['Unassigned'],
    }),
    editAssignment: builder.mutation({
      query: ({id, data}) => ({
        url: `edit/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
        headers: headers,
      }),
      onQueryStarted: () => {
        console.log('updateAssignment query started');
      },
    }),
    transferAssignment: builder.mutation({
      query: ({id, data}) => ({
        url: `transfer/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
        headers: headers,
      }),
      onQueryStarted: () => {
        console.log('transferAssignment query started');
      },
    }),
  }),
});

export const { useEditAssignmentMutation, useGetAssignmentQuery, useGetUnassignedQuery, useGetCrewAssignmentsQuery, useTransferAssignmentMutation } = AssignmentApi;

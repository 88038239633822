import { Menu, Divider, Button, Box } from "@mui/material";
import Toggle from "../inputs/Toggle";

export default function ColumnToggleMenu({anchorEl, open, handleClose, columns, setColumns}) {
    return (
        <Menu
            anchorReference="anchorPosition"
            open={open}
            onClose={handleClose}
            anchorPosition={{top: anchorEl.top, left: anchorEl.left}}
            transformOrigin={{horizontal: 'left', vertical: 'top'}}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            id="toggle-columns-menu"
            slotProps={{
            paper: {
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    boxShadow: '0 0 1px rgba(0,0,0,0.24), 0 0 2px rgba(0,0,0,0.16), 0 3px 4px rgba(0,0,0,0.06), 0 6px 8px rgba(0,0,0,0.06), 0 12px 16px rgba(0,0,0,0.08), 0 18px 32px rgba(0,0,0,0.06)',
                    width: 296,
                    padding: '.75rem',
                    pt: '4px',
                },
            },
            }}
        >

            <Box sx={{display: "flex", flexDirection: "row", mb: "0.5rem"}}>
                <Button 
                    onClick={() => {
                        // Set all columns' shown property to true
                        const newColumns = columns.map((col) => {
                            return {...col, shown: true};
                        });
                        setColumns(newColumns);
                    }}
                    sx={{
                        textTransform: "none",
                    }}
                >
                    Show All
                </Button>
                <Button
                    onClick={() => {
                        // Set all columns' shown property to false except the first column
                        const newColumns = columns.map((col) => {
                            if (col.id === 1) {
                                return col;
                            } else {
                                return {...col, shown: false};
                            }
                        });
                        setColumns(newColumns);
                    }}
                    sx={{
                        textTransform: "none",
                        ml: "auto"
                    }}
                >
                    Hide All
                </Button>
            </Box>

            <Divider sx={{mb: "1rem"}}/>

            {/* Map the filters to Toggle components */}
            {columns.filter((column) => column.id !== 1).map((column) => (
                <Toggle 
                    key={column.id}
                    label={column.label} 
                    checked={column.shown}
                    onChange={() => {
                        // Set the column's shown property to the opposite of what it currently is
                        const newColumns = columns.map((col) => {
                            if (col.id === column.id) {
                                return {...col, shown: !col.shown};
                            } else {
                                return col;
                            }
                        });
                        setColumns(newColumns);
                    }}
                />
            ))}

        </Menu>
    );
}
import { IconButton } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export default function ViewRunButton({run}) {
    return (
        <a 
            href={`https://adminblewinc.quickbase.com/db/btqa2ug74?a=dr&rid=${run?.['3']}&rl=9g`}
            target="_blank" 
            rel="noopener noreferrer" 
            style={{textDecoration: 'none'}}
        >
            <IconButton     
                size="small" 
                sx={{color: '#1976d2'}}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <VisibilityOutlinedIcon sx={{fontSize: "14px"}}/>
            </IconButton>
        </a>
    )
}
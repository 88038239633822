import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SearchOffOutlinedIcon from '@mui/icons-material/SearchOffOutlined';
import IconButtonBase from "./IconButtonBase";

export default function ToggleSearchButton({searchShown, setSearchShown}) {
    return (
        <IconButtonBase
            handleClick={() => setSearchShown(!searchShown)}
            tooltip="Toggle Search"
        >
            { searchShown ?
                <SearchOutlinedIcon 
                    sx={{
                        color: "rgb(51, 51, 51)",
                        fontSize: "18px"
                    }}
                />
                :
                <SearchOffOutlinedIcon
                    sx={{
                        color: "rgb(51, 51, 51)",
                        fontSize: "18px"
                    }}
                />
            }
        </IconButtonBase>
    )
}
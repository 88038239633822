import { Checkbox } from "@mui/material"

export default function ModalCheckbox({value, handleChange}) {
    return (
        <Checkbox
            size="small"
            sx={{
                color: "rgb(51, 51, 51)",
                fontSize: "18px",
                transform: 'scale(1)',
                '&.MuiCheckbox-root': {
                    padding: '2px',
                },
            }}
            checked={value}
            onChange={(e) => {
                handleChange(e);
            }}
        />
    )
}
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_API;

export const CrewApi = createApi({
  reducerPath: 'CrewApi',
  tagTypes: ['Crews'],
  baseQuery: fetchBaseQuery({baseUrl: `${BASE_URL}/employees`}),
  endpoints: (builder) => ({
    createCrew: builder.mutation({
      query: (crew) => ({
        url: '/create',
        method: 'POST',
        body: JSON.stringify(crew),
      }),
      onQueryStarted: () => {
        console.log('createCrew query started');
      },
      invalidatesTags: ['Crews'],
    }),
    getCrews: builder.query({
      query: () => `/crew/list`,
      onQueryStarted: () => {
        console.log('getCrews query started');
      },
      providesTags: ['Crews'],
    }),
    getFieldProfessionals: builder.query({
      query: () => `/field-professional/list`,
      onQueryStarted: () => {
        console.log('getFieldProfessionals query started');
      },
      providesTags: ['Crews'],
    }),
    getSubcontractors: builder.query({
      query: () => `/subcontractor/list`,
      onQueryStarted: () => {
        console.log('getSubcontractors query started');
      },
      providesTags: ['Crews'],
    }),
    updateCrew: builder.mutation({
      query: (crew) => ({
        url: `/edit/${crew.record_id}`,
        method: 'PUT',
        body: JSON.stringify(crew),
      }),
      onQueryStarted: () => {
        console.log('updateCrew query started');
      },
      invalidatesTags: ['Crews'],
    }),
    deleteCrew: builder.mutation({
      query: (crew) => ({
        url: `/delete/${crew.record_id}`,
        method: 'DELETE',
        body: JSON.stringify(crew),
      }),
      onQueryStarted: () => {
        console.log('deleteCrew query started');
      },
      invalidatesTags: ['Crews'],
    }),
  }),
});

export const { useCreateCrewMutation, useDeleteCrewMutation, useGetCrewsQuery, useUpdateCrewMutation, useGetFieldProfessionalsQuery, useGetSubcontractorsQuery } = CrewApi;

export function handleDrop(result, selectedItems, setSelectedItems, crews, setCrews, unassignedRecords, setUnassignedRecords) {
    // If the drop is outside of a droppable, do nothing
    if (!result.destination) return;

    // If the source and destination are the same, order the assignments based on the result.destination.index
    if (result.source.droppableId === result.destination.droppableId) {
        // If the source is NOT the unassigned droppable, update the assignment records
        if (result.source.droppableId !== 'unassigned') {
            const sourceCrew = crews.find(crew => crew['3'] === Number(result.source.droppableId));
            const assignment = sourceCrew['assignments'].find(assignment => assignment['3'] === Number(result.draggableId));
            sourceCrew['assignments'].splice(result.source.index, 1);
            sourceCrew['assignments'].splice(result.destination.index, 0, assignment);
            const newCrews = [...crews];
            const sourceCrewIndex = newCrews.findIndex(crew => crew['3'] === sourceCrew['3']);
            newCrews[sourceCrewIndex] = sourceCrew;
            setCrews(newCrews);
            setSelectedItems([]);
            return;
        }
        return;
    }

    // If the source is the unassigned droppable...
    if (result.source.droppableId === 'unassigned') {
        // Moving an assignment from unassigned to a crew
        // 1. Remove the assignment from the unassigned records
        // 2. Add the assignment to the destination crew
        // 3. Update the crews state
        // 4. Update the unassigned records state
        const assignment = unassignedRecords.find(assignment => assignment['3'] === Number(result.draggableId));
        // Update the unassigned records
        const newUnassignedRecords = [...unassignedRecords];
        const assignmentIndex = newUnassignedRecords.findIndex(assignment => assignment['3'] === Number(result.draggableId));
        newUnassignedRecords.splice(assignmentIndex, 1);
        setUnassignedRecords(newUnassignedRecords);
        // Update the destination crew
        const destinationCrew = crews.find(crew => crew['3'] === Number(result.destination.droppableId));

        // Using the result.destination.index, we can insert the assignment at the correct index
        const destinationIndex = result.destination.index;
        destinationCrew['assignments'].splice(destinationIndex, 0, assignment);

        // Update the crews state
        const newCrews = [...crews];
        const destinationCrewIndex = newCrews.findIndex(crew => crew['3'] === destinationCrew['3']);
        newCrews[destinationCrewIndex] = destinationCrew;
        setCrews(newCrews);
        setSelectedItems([]);
        return;
    }

    // If the destination is the unassigned droppable...
    if (result.destination.droppableId === 'unassigned') {
        // Moving an assignment from a crew to unassigned
        // 1. Add the assignment to the unassigned records
        // 2. Remove the assignment from the source crew
        // 3. Update the crews state
        // 4. Update the unassigned records state
        const sourceCrew = crews.find(crew => crew['3'] === Number(result.source.droppableId));
        const assignment = sourceCrew['assignments'].find(assignment => assignment['3'] === Number(result.draggableId));
        // Update the unassigned records
        const newUnassignedRecords = [...unassignedRecords];
        newUnassignedRecords.push(assignment);
        setUnassignedRecords(newUnassignedRecords);
        // Update the source crew
        const sourceAssignmentIndex = sourceCrew['assignments'].findIndex(assignment => assignment['3'] === Number(result.draggableId));
        sourceCrew['assignments'].splice(sourceAssignmentIndex, 1);
        // Update the crews state
        const newCrews = [...crews];
        const sourceCrewIndex = newCrews.findIndex(crew => crew['3'] === sourceCrew['3']);
        newCrews[sourceCrewIndex] = sourceCrew;
        setCrews(newCrews);
        setSelectedItems([]);
        return;
    }

    if (selectedItems.length > 1) {
        // Selected items is a list of draggableIDs (assignments)
        return;
    } else {
        // Moving a single item from one crew to another
        // 1. Remove the assignment from the source crew
        // 2. Add the assignment to the destination crew
        // 3. Update the crews state
        const sourceCrew = crews.find(crew => crew['3'] === Number(result.source.droppableId));
        const destinationCrew = crews.find(crew => crew['3'] === Number(result.destination.droppableId));
        const assignment = sourceCrew['assignments'].find(assignment => assignment['3'] === Number(result.draggableId));
        // Update the source crew
        const sourceAssignmentIndex = sourceCrew['assignments'].findIndex(assignment => assignment['3'] === Number(result.draggableId));
        sourceCrew['assignments'].splice(sourceAssignmentIndex, 1);
        // Update the destination crew
        const destinationIndex = result.destination.index;
        destinationCrew['assignments'].splice(destinationIndex, 0, assignment);

        // Update the crews state
        const newCrews = [...crews];
        const sourceCrewIndex = newCrews.findIndex(crew => crew['3'] === sourceCrew['3']);
        const destinationCrewIndex = newCrews.findIndex(crew => crew['3'] === destinationCrew['3']);
        newCrews[sourceCrewIndex] = sourceCrew;
        newCrews[destinationCrewIndex] = destinationCrew;
        setCrews(newCrews);
        setSelectedItems([]);
        return;
    }
};
import { TextField, InputAdornment } from "@mui/material"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

export default function Searchbar({searchTerm, setSearchTerm, searchShown}) {

    return (
        <div className={`min-w-[270px] flex justify-center items-center h-full mr-2 ml-auto transition-all`}>
            { searchShown ?
                <TextField
                    fullWidth
                    placeholder={'Search by employee...'}
                    name="search"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    sx={{
                        fontSize: "12px",
                        "& fieldset": { border: '1px solid #D9DBDE' },
                        "& .MuiInputBase-multiline": { padding: '0' },
                    }}
                    inputProps={{ 
                        style: {
                            fontSize: '12px',
                            padding: '8px',
                            lineHeight: '1.6',
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlinedIcon sx={{color: "#D9DBDE", fontSize: "16px"}}/>
                            </InputAdornment>
                        )
                    }}
                />
                :
                null
            }
        </div>
    )
}
import {Modal, Box} from '@mui/material';

export default function ModalBase({open, handleClose, children, ...props}) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '720px',
    minWidth: '360px',
    bgcolor: 'background.paper',
    maxHeight: 'calc(100vh - 64px)',
    overflowY: 'auto',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 0px 1px, rgba(0, 0, 0, 0.16) 0px 0px 2px, rgba(0, 0, 0, 0.06) 0px 3px 4px, rgba(0, 0, 0, 0.06) 0px 6px 8px, rgba(0, 0, 0, 0.08) 0px 12px 16px, rgba(0, 0, 0, 0.06) 0px 18px 32px',
    px: '0rem',
    py: '0.5rem',
    borderRadius: '6px',
    zIndex: '10000',
    ...props,
  };

  return (
      <Modal
        open={open}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {children}
        </Box>
      </Modal>
  );
}
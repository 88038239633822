import {Button} from '@mui/material';
import { useScheduleRunsMutation, useScheduleUnassignedMutation } from '../../../../services/SchedulerApi';

export default function SubmitButton({crews, setAlertMessage, setSaving, unassignedRecords, transferRecords, setUnsavedChanges}) {
  const [scheduleRuns] = useScheduleRunsMutation();
  const [scheduleUnassigned] = useScheduleUnassignedMutation();

  return (
    <Button
        onClick={() => {
            setSaving(true);

            Promise.all([
              scheduleRuns(crews).unwrap(),
              scheduleUnassigned(unassignedRecords).unwrap()
            ])
            .then(([runsResult, unassignedResult]) => {
                console.log("Runs result", runsResult);
                console.log("Unassigned result", unassignedResult);
                setAlertMessage("Changes saved successfully!");
                setUnsavedChanges(false);
            })
            .catch((err) => {
                console.log("Error", err?.data);
                setAlertMessage(err?.data ? err.data : "Error saving changes. Contact an administrator.");
            })
            .finally(() => {
                setSaving(false);
            });
        }}
        sx={{
            'textTransform': 'none',
            'fontSize': '13px',
            'color': '#fff',
            'backgroundColor': '#4473D2',
            'letterSpacing': 'normal',
            'ml': 1,
            'mr': 1,
            'width': '142px',
            '&:hover': {backgroundColor: '#4473D2', filter: 'brightness(0.95)'},
        }}
    >
      Save Changes
    </Button>
  );
}

import { Button } from "@mui/material";

export default function CancelButton({handleClose}) {
    return (
        <Button
            onClick={() => {
                handleClose();
            }}
            sx={{
                'textTransform': 'none',
                'fontSize': '13px',
                'color': 'rgb(51, 51, 51)',
                'backgroundColor':'#fff',
                'letterSpacing': 'normal',
                'ml': '0rem',
                '&:hover': {backgroundColor: '#fff', filter: 'brightness(0.95)'},
            }}
        >
            Cancel
        </Button>
    )
}
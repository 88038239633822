import Textarea from '@mui/joy/Textarea';

export default function CustomMultiline({...props}) {
  return (
    <Textarea
      sx={{
        'width': '100%',
        'fontSize': '12px',
        'mt': '0.5rem',
        'boxShadow': 'none',
        'backgroundColor': '#fff',
        'border': '1px solid #c1c1c1',
        'px': '8px',
        '&:hover': {
          border: '1px solid #4473D2',
        },
        '&:focus': {
          border: '1px solid #4473D2',
          borderWidth: '1px !important',
          outline: 'none',
        },
      }}
      {...props}
    />
  );
}
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Logo from '../../../assets/blew_logo.png';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';

const messages = [
    "Loading your data...",
    "Almost there...",
];

export default function Loading({loading}) {

    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) =>
          (prevIndex + 1) % messages.length
        );
      }, 5000);
  
      return () => clearInterval(interval);
    }, []);

    return (
        <Fade in={loading}>
            <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 9999, // adjust the z-index as needed
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: "column",
                backgroundColor: "#fff"
            }}
            >
                <img src={Logo} alt="Blew Logo" className='h-36 mb-12' />
                {messages.map((message, index) => (
                    <Fade 
                        key={index} 
                        in={index === currentMessageIndex} 
                        timeout={1000}
                        sx={{
                            display: index === currentMessageIndex ? 'block' : 'none',
                        }}
                    >
                        <Typography
                        style={{
                            marginBottom: '1rem',
                            color: 'rgb(51, 51, 51)',
                            fontWeight: '500',
                            fontSize: '16px',
                        }}
                        >
                        {message}
                        </Typography>
                    </Fade>
                ))}
                <LinearProgress sx={{width: "50%"}} />
            </Box>
        </Fade>
    );
}
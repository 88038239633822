import ModalHeader from "../ModalHeader";
import CustomMultiline from "../../inputs/CustomMultiline";
import {Typography} from "@mui/material";
import { useEditRunMutation } from "../../../../../services/RunApi";
import { useState } from "react";
import Buttons from "./buttons/Buttons";

export default function EditRunModal({run, crews, setCrews, handleClose, setAlertMessage}) {

    const [editRun] = useEditRunMutation();
    const [notes, setNotes] = useState(run?.['16']);
    const [error, setError] = useState('');

    return (
        <div className='flex flex-col w-full h-full pb-2'
        >
            <ModalHeader handleClose={handleClose} message={`Run #${run?.['3']}: ${run?.['12']}`} />

            <div className="flex flex-col px-6 py-2">
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: '500',
                    }}
                >
                    Notes
                </Typography>

                <CustomMultiline
                    minRows={3}
                    placeholder="Notes"
                    value={notes}
                    error={error === '' ? false : true}
                    onChange={(event) => {
                        setNotes(event.target.value);
                        setError('');
                    }}
                />

                { error !== '' ?
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: 'red',
                            mt: '0.5rem',
                        }}
                    >
                        Something went wrong while saving! {error}
                    </Typography>
                :
                    null
                }
            </div>
            <Buttons  
                handleClose={handleClose}
                submit={() => {
                    editRun({
                        id: run?.['3'],
                        data: {'notes': notes},
                    })
                    .unwrap()
                    .then((res) => {
                        console.log(res);
                        const newRun = {...run};
                        newRun['16'] = notes;
                        
                        // Find the crew which satisfies the condition:
                        // run['11'] === crew['6']
                        const crew = crews.find((crew) => crew['6'] === run['11']);
                        // Update the crew's runs array with the new run
                        const newCrew = {...crew};
                        newCrew['runs'] = [...crew['runs']];
                        const runIdx = newCrew['runs'].findIndex((run) => run['3'] === newRun['3']);
                        newCrew['runs'][runIdx] = newRun;
                        // Update the crews array with the new crew
                        const newCrews = [...crews];
                        const crewIdx = newCrews.findIndex((crew) => crew['6'] === newCrew['6']);
                        newCrews[crewIdx] = newCrew;
                        // Update the crews state
                        setCrews(newCrews);

                        setAlertMessage('Successfully edited run!');
                        handleClose();
                    })
                    .catch((err) => {
                        console.log(err);
                        setError(err?.data?.detail);
                    })
                }}
            />
        </div>
    )
}
import ToggleColumnsButton from "../buttons/ToggleColumnsButton"
import ToggleFilterButton from "../buttons/ToggleFilterButton"
import ToggleSearchButton from "../buttons/ToggleSearchButton"
import SubmitButton from "../buttons/SubmitButton"
import Searchbar from "../inputs/Searchbar"
import { Typography } from "@mui/material"

export default function TableHeader({showFilters, toggleFilters, searchShown, setSearchShown, searchTerm, setSearchTerm, columns, setColumns, crews, setAlertMessage, setSaving, unassignedRecords}) {

    return (
        <div className="h-12 bg-white flex items-center px-2 w-full z-[999] box-border fixed top-0 left-0">

            <Typography
                sx={{
                    fontSize: '1rem',
                    fontWeight: '600',
                }}
            >
                Construction Services Scheduler
            </Typography>
            
            <Searchbar searchShown={searchShown} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

            <div className={`flex flex-row justify-center items-center h-full ml-0`}>
                <ToggleSearchButton searchShown={searchShown} setSearchShown={setSearchShown} />
                <ToggleFilterButton showFilters={showFilters} toggleFilters={toggleFilters} />
                <ToggleColumnsButton columns={columns} setColumns={setColumns} />
            </div>

            <SubmitButton crews={crews} setAlertMessage={setAlertMessage} setSaving={setSaving} unassignedRecords={unassignedRecords} />
        </div>
    )
}
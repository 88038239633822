import { IconButton } from "@mui/material";
import { useState } from "react";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ModalBase from "../popups/ModalBase";
import TransferAssignmentModal from "../popups/TransferAssignmentModal";

export default function TransferAssignmentButton({assignment, run, crews, setCrews, setAlertMessage}) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <IconButton 
                size="small" 
                sx={{color: '#DC053C'}}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                }}
            >
                <SwapHorizIcon sx={{fontSize: "14px"}}/>
            </IconButton>
            {open && (
                <ModalBase 
                    minWidth={'720px !important'}
                    open={open}
                    handleClose={() => {
                        setOpen(false);
                    }}
                >
                    <TransferAssignmentModal assignment={assignment} handleClose={() => {setOpen(false)}} crews={crews} setCrews={setCrews} run={run} setAlertMessage={setAlertMessage}/>
                </ModalBase>
            )}
        </>
    )
}
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_API;

export const EmployeeApi = createApi({
  reducerPath: 'EmployeeApi',
  tagTypes: ['Employees'],
  baseQuery: fetchBaseQuery({baseUrl: `${BASE_URL}/employees`}),
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: () => `/list`,
      onQueryStarted: () => {
        console.log('getEmployees query started');
      },
      providesTags: ['Employees'],
    }),
  }),
});

export const { useGetEmployeesQuery } = EmployeeApi;

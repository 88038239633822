import { Typography, Tooltip } from "@mui/material"
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import EditRunButton from "./buttons/EditRunButton";
import ViewRunButton from "./buttons/ViewRunButton";

export default function Run({run, crews, setCrews, setAlertMessage}) {
    return (
        <div className="w-full p-1 bg-yellow-100 relative flex flex-row items-center">
            <div className="sticky left-2 flex flex-row items-center max-w-[100vw]">
            <EditRunButton run={run} crews={crews} setCrews={setCrews} setAlertMessage={setAlertMessage}/>
            <ViewRunButton run={run} />
            {run?.['17'] ?
                <Tooltip title={'Crew Hand'} placement="bottom">
                    <PersonAddAlt1Icon sx={{mr: '0.5rem', color: 'green'}} />
                </Tooltip>
                :
                null
            }
            <Typography sx={{fontWeight: "500", whiteSpace: 'nowrap', fontSize: '12px'}}>
                {run?.['25']} - {run?.['26']} (Time off request dates)
            </Typography>
            <Typography sx={{fontWeight: "500", mx: '0.5rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px'}}>
                Notes: {run?.['16']}
            </Typography>
            </div>
        </div>
    )
}
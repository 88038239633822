import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import getCookie from './CSRF';

const BASE_URL = process.env.REACT_APP_API;

const headers = {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCookie('csrftoken')
};

export const SchedulerApi = createApi({
  reducerPath: 'SchedulerApi',
  tagTypes: ['Runs', 'FieldProfessionals', 'Unassigned', 'Transfers', 'Subcontractors'],
  baseQuery: fetchBaseQuery({baseUrl: `${BASE_URL}/scheduler`}),
  endpoints: (builder) => ({
    getRuns: builder.query({
      query: () => `/survey/run/list`,
      onQueryStarted: () => {
        console.log('getRuns query started');
      },
      providesTags: ['Runs'],
    }),
    getTransfers: builder.query({
      query: () => `/survey/transfer/list`,
      onQueryStarted: () => {
        console.log('gettrasfers query started');
      },
      providesTags: ['Transfers'],
    }),
    createFieldProfessional: builder.mutation({
      query: (data) => ({
        url: `/assessment/field-professional/create`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers,
      }),
      invalidatesTags: ['FieldProfessionals'],
    }),
    createSubcontractor: builder.mutation({
      query: (data) => ({
        url: `/construction-services/subcontractor/create`,
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers,
      }),
      invalidatesTags: ['Subcontractors'],
    }),
    editRun: builder.mutation({
      query: ({id, data}) => ({
        url: `/survey/run/edit/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
        headers: headers,
      }),
      invalidatesTags: ['Runs'],
    }),
    scheduleRuns: builder.mutation({
      query: (runs) => ({
        url: `/survey/run/schedule`,
        method: 'PUT',
        body: JSON.stringify(runs),
        headers: headers,
      }),
      invalidatesTags: ['Runs'],
    }),
    scheduleFieldProfessionals: builder.mutation({
      query: (fieldProfessionals) => ({
        url: `/assessment/field-professional/schedule`,
        method: 'PUT',
        body: JSON.stringify(fieldProfessionals),
        headers: headers,
      }),
      invalidatesTags: ['FieldProfessionals'],
    }),
    scheduleSubcontractors: builder.mutation({
      query: (subcontractors) => ({
        url: `/construction-services/subcontractor/schedule`,
        method: 'PUT',
        body: JSON.stringify(subcontractors),
        headers: headers,
      }),
      invalidatesTags: ['Subcontractors'],
    }),
    scheduleUnassigned: builder.mutation({
      query: (unassigned) => ({
        url: `/unassigned/schedule`,
        method: 'PUT',
        body: JSON.stringify(unassigned),
        headers: headers,
      }),
      invalidatesTags: ['Unassigned'],
    }),
  }),
});

export const { 
  useGetRunsQuery, 
  useGetTransfersQuery,
  useEditRunMutation, 
  useScheduleRunsMutation, 
  useScheduleUnassignedMutation,
  useScheduleFieldProfessionalsMutation,
  useScheduleSubcontractorsMutation,
  useCreateSubcontractorMutation,
  useCreateFieldProfessionalMutation,
 } = SchedulerApi;

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import IconButtonBase from "./IconButtonBase";
import { useState } from "react";
import ColumnToggleMenu from '../menus/ColumnToggleMenu'

export default function ToggleColumnsButton({columns, setColumns}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
  
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };
  
    const handleOpen = (e) => {
        setOpen(true);
        setAnchorEl({top: e.clientY, left: e.clientX});
    };


    return (
        <>
            <IconButtonBase
                handleClick={(e) => {
                    handleOpen(e);
                }}
                tooltip={"Toggle Columns"}
            >
                <VisibilityOutlinedIcon 
                    sx={{
                        color: "rgb(51, 51, 51)",
                        fontSize: "18px"
                    }}
                />
            </IconButtonBase>
            {open && <ColumnToggleMenu open={open} anchorEl={anchorEl} handleClose={handleClose} columns={columns} setColumns={setColumns}/>}
        </>
    )
}
import { Box, Typography, Checkbox } from "@mui/material";
import { Draggable } from "react-beautiful-dnd"
import EditAssignmentButton from "./buttons/EditAssignmentButton";
import ViewAssignmentButton from "./buttons/ViewAssignmentButton";
import TransferAssignmentButton from "./buttons/TransferAssignmentButton";

const getColorPair = (snapshot, assignment) => {
    if (snapshot.isDragging) {
        return { backgroundColor: "#def4ff", textColor: "rgb(51, 51, 51)" };
    }

    if (assignment?.['173'] === true) {
        return { backgroundColor: "#ADD8E6", textColor: "rgb(51, 51, 51)" };
    }

    if (assignment?.['78'] === 'Complete') {
        return { backgroundColor: "#C4D9B0", textColor: "rgb(51, 51, 51)" };
    }

    if (assignment?.['175'] === 'Cancelled') {
        return { backgroundColor: "#F50902", textColor: "#fff" };
    }

    if (assignment?.['175'] === 'Hold') {
        return { backgroundColor: "#000", textColor: "#fff" };
    }

    return { backgroundColor: "#fff", textColor: "rgb(51, 51, 51)" };
}

export default function Assignment({assignment, columns, index, crews, setCrews, crew, setAlertMessage}) {
    const shownColumns = columns.filter(column => column.shown);
    return (
        <Draggable key={assignment?.['3']} draggableId={String(assignment?.['3'])} index={index}>
            {(provided, snapshot) => {
                const { backgroundColor, textColor } = getColorPair(snapshot, assignment);
                return (
                    <div 
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`flex flex-row h-8 w-full border-b border-gray-200 bg-white relative`}
                    >
                        { 
                            shownColumns.map((column, index) => (
                                <Box 
                                key={index}
                                title={String(assignment?.[column?.field_id])}
                                sx={{
                                    borderRight: "1px solid #E5E7EB",
                                    height: "100%",
                                    minWidth: column.width,
                                    maxWidth: column.width,
                                    display: "flex",
                                    justifyContent: column.align,
                                    alignItems: "center",
                                    padding: "0px 8px",
                                    boxSizing: "border-box",
                                    backgroundColor: assignment?.['180'] !== '' && column?.field_id === 87 ? assignment?.['180'] : backgroundColor,
                                    // overflow: "hidden",
                                    position: column.id === 1 ? "sticky" : "relative",
                                    left: column.id === 1 ? "0px" : "auto",
                                    zIndex: column.id === 1 ? "100" : "0",
                                }}
                                >
                                     { column.id !== 1 ? null : 
                                            <>
                                                <EditAssignmentButton assignment={assignment} crews={crews} setCrews={setCrews} crew={crew} setAlertMessage={setAlertMessage}/>
                                                <ViewAssignmentButton assignment={assignment}/>
                                                <TransferAssignmentButton assignment={assignment} crew={crew} crews={crews} setCrews={setCrews} setAlertMessage={setAlertMessage} />
                                            </>
                                    }
                                    { column.type !== 'boolean' ?
                                        <Typography
                                            sx={{
                                                fontSize: "12px", 
                                                color: assignment?.['180'] !== '' && column?.field_id === 87 ? 'rgb(51, 51, 51)' : textColor,
                                                textOverflow: "ellipsis", 
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                            }}
                                        >
                                            {assignment?.[column?.field_id]}
                                        </Typography>
                                        :
                                        <Checkbox
                                            size="small"
                                            sx={{
                                                color: textColor,
                                            }}
                                            checked={assignment?.[column?.field_id]}
                                        />
                                    }
                                </Box>
                            ))
                        }
                    </div>
                )
            }}
        </Draggable>
    )
}
// Redux persist storage
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

// Creating a no-operation storage for environments where window object is not available,
// like in server-side rendering or in tests
const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

// Conditional storage creation: using web storage if window object is available,
// otherwise using the no-operation storage
const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

export default storage;

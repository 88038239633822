import { Typography, Divider, CircularProgress } from "@mui/material";

export default function Saving() {
    return (
        <div className="w-full px-6 py-4 flex flex-col items-center">
            <Typography sx={{ fontSize: '18px', color: 'rgb(51, 51, 51)', fontWeight: '500', textAlign: 'left', lineHeight: 'normal', paddingY: '.5rem' }}>Saving Changes</Typography>
            <Divider sx={{ my: '0.5rem', width: '100%' }} />
            <CircularProgress sx={{ my: '1rem' }} />
        </div>
    )
}
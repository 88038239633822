import { Typography, IconButton } from "@mui/material"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export default function UnassignedHeader({setShowUnassigned}) {
    return (
        <div className="flex flex-col border-b border-gray-200 sticky top-0 left-0 bg-white z-[9999] w-[2250px]">

            <div className="flex flex-row h-9 pl-1 bg-[#DC053C]">
                <div className="h-full sticky left-3 bg-white rounded-t-md flex items-center px-3 shadow-[rgba(0,0,0,0.32)_0px_0px_1px,rgba(0,0,0,0.08)_0px_0px_2px,rgba(0,0,0,0.08)_0px_1px_3px]">
                    <Typography sx={{fontSize: '13px', color: 'rgb(51, 51, 51)', fontWeight: '500', textAlign: 'center', lineHeight: 'normal'}}>Unassigned</Typography>
                    <IconButton 
                        size="small"
                        sx={{
                            backgroundColor: 'white',
                            position: 'fixed',
                            right: '1rem',
                            '&:hover': {
                                backgroundColor: 'white',
                                filter: 'brightness(0.8)'
                            }
                        }}
                        onClick={() => setShowUnassigned(false)}
                    >
                        <CloseOutlinedIcon sx={{fontSize: '14px', color: 'red'}}/>
                    </IconButton>
                </div>
            </div>

            <div className="flex flex-row h-8 items-center bg-[#F5F5F5]">

                <div className="h-full w-[150px] px-2 border-t border-r border-gray-200 sticky left-0 z-9999 bg-inherit flex items-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Blew Job #</Typography>
                </div>

                <div className="h-full w-[150px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>State</Typography>
                </div>

                <div className="h-full w-[200px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>City</Typography>
                </div>

                <div className="h-full w-[150px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Due Date</Typography>
                </div>

                <div className="h-full w-[200px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Price</Typography>
                </div>

                <div className="h-full w-[250px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Report Type</Typography>
                </div>

                <div className="h-full w-[200px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Revisit Reason</Typography>
                </div>
                    
                <div className="h-full w-[100px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Priority Job</Typography>
                </div>

                <div className="h-full w-[250px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Possible Navis Job</Typography>
                </div>

                <div className="h-full w-[300px] px-2 border-t border-r border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Scheduling Notes</Typography>
                </div>

                <div className="h-full w-[300px] px-2 border-t border-gray-200 flex items-center justify-center">
                    <Typography sx={{fontSize: '12px', color: 'rgb(51, 51, 51)', fontWeight: '400'}}>Crew Notes</Typography>
                </div>
            </div>
        </div>
    )
}
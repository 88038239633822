import {CrewApi} from '../../services/CrewApi';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  crews: [],
};

export const CrewSlice = createSlice({
  name: 'crew',
  initialState: initialState,
  reducers: {
    setCrews: (state, action) => {
      state.crews = action.payload;
    },
    resetCrewState: () => initialState,
  },
  // The `extraReducers` field lets the slice handle actions defined in the authApi slice
  extraReducers: (builder) => {
    builder
      .addMatcher(CrewApi.endpoints.getCrews.matchFulfilled, (state, {payload}) => {
        state.crews = payload;
      })
      .addMatcher(CrewApi.endpoints.getFieldProfessionals.matchFulfilled, (state, {payload}) => {
        state.crews = payload;
      })
      .addMatcher(CrewApi.endpoints.getSubcontractors.matchFulfilled, (state, {payload}) => {
        state.crews = payload;
      })
  },
});

// Action creators are generated for each case reducer function
export const {setCrews, resetCrewState} = CrewSlice.actions;

export const selectCrews = (state) => state.crew.crews;

export default CrewSlice.reducer;

import CheckboxFilter from "./CheckboxFilter";
import TextFilter from "./TextFilter";

export default function ColumnFilter({column, filters, setFilters}) {
    switch (column.type) {
        case "boolean":
            return <CheckboxFilter column={column} filters={filters} setFilters={setFilters} />
        case "text":
            return <TextFilter column={column} filters={filters} setFilters={setFilters} />
        default:
            return null;
    }
}